// Core
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';

// Components
// import Hint from '../../common/Hint';
import Select from '../../common/Select';
import Pencil from '../../icons/Pencil';

// Hooks
import { useDropzoneEl } from "../../../hooks/custom/useDropzoneEl";
import { useInvalidFields } from "../../../hooks/custom/useInvalidFields";
import { usePrevious } from "../../../hooks/custom/usePrevious";
import { useLists } from "../../../hooks/useLists";
import { useModals } from "../../../hooks/useModals";
import { useProject } from "../../../hooks/useProject";
import { useUi } from "../../../hooks/useUi";
import { useUser } from "../../../hooks/useUser";

// Instruments
import cx from 'classnames';
import {
    bytesToSize,
    clearBrief,
    getAppTitle,
    getFormat,
    getTitle,
    initDefaultRedactor,
    onOutsideElClick,
    removeScriptTags,
    setLinksTarget,
    getCurrencyCode,
    toLocaleString,
    upperCaseFirstLetter,
} from '../../../helpers/helpers';
import animateScrollTo from 'animated-scroll-to';
import { isEmpty, isNil, includes, uniq } from 'ramda';

const imagesLabels = {
    'browse-images': 'Browse images',
    'find-images-for-me': 'Find images for me',
    'use-attached': 'Use attached images',
    'do-not-use': 'Do not use images',
};
const maxSize = 50000000; // = 50Mb = 50000000 bytes
const maxSize1Gb = 1080000000; // = 1Gb = 1080000000 bytes

export const Brief = ({ company }) => {
    /* Ref */
    const noWritersRef = useRef(null);
    const writersListRef = useRef(null);

    /* State */
    const [project, setProject] = useState({});
    const {
        // smart_match,
        additional_details,
        attachments,
        avoid,
        brand_profiles,
        brief,
        competitors,
        content,
        content_goal,
        copy_style,
        copy_voice,
        currency,
        design_references,
        dimensions,
        file_types,
        files,
        format,
        image_notes,
        image_option,
        image_option_files,
        included_text,
        industry,
        invite_writers,
        invite_writer_active_for,
        job_type,
        localization,
        mention,
        other_industry = '',
        product_service,
        project_tags,
        revision,
        similar_content,
        status,
        target_audience,
        target_location,
        topic,
        word_count,
        level,
        logo_file,
        brand_description,
        ideas,
        examples,
        length: animationLength,
        format_options,
        format_description,
        format_reason,
        format_target_audience,
        website,
        cms,
        google_analytics,
        google_search_console,
        link_to_resources,
        resources,
        products,
        goals,
        notes,
        seo_type,
        seo_audit_notes,
        focus_areas,
        keywords_google,
        keywords,
        marketing_channels,
        monitoring_tools,
        google_access,
        seo_strategies,
        link_analized,
        seo_audited,
        pay_type,
        idea_files,
        idea_attachments,
        used_services,
        workflow,
        sitemap,
        review_frequency,
        seobrief_type,
        unique_selling,
        target_keywords,
        call_to_action,
        content_reference,
        content_themes
    } = project;

    const prevImageOption = usePrevious(image_option);
    const [isEditing, setIsEditing] = useState(false);
    const [isBriefLimit, setIsBriefLimit] = useState(false);

    /* Actions */
    const onSetProjectState = (data) => {
        const obj = {
            attachments: isEmpty(data.files) ? [] : data.files
        };
        if ( includes(data.job_type, 'keywords/webaudit/seoaudit/seobrief/contentstrategy/linkbuilding') ) {

        } else if ( data.job_type === 'motion' ) {
            obj['attachments'] = isEmpty(data.logo_file) ? [] : data.logo_file;
            obj['idea_attachments'] = isEmpty(data.idea_files) || isNil(data.idea_files) ? [] : data.idea_files;
            obj['file_types'] = data.file_types.map(o => o.code);
            obj['invite_writers'] = isEmpty(data.invite_writers) ? [] : JSON.parse(data.invite_writers);
            obj['format_options'] = isNil(data.format_options) ? null : data.format_options.map(o => JSON.parse(o));
        } else if ( data.job_type === 'design' ) {
            obj['file_types'] = data.file_types.map(o => o.code);
            obj['invite_writers'] = isEmpty(data.invite_writers) ? [] : JSON.parse(data.invite_writers);
            obj['format_options'] = isNil(data.format_options) ? null : data.format_options.map(o => JSON.parse(o));
        } else {
            obj['invite_writers'] = isEmpty(data.invite_writers) ? [] : JSON.parse(data.invite_writers);
        }

        setProject({ ...data, ...obj });
    };
    const onUpdateFiles = (prop, value, id) => {
        if ( includes(prop, 'files/logo_file/idea_files') ) {
            setProject(state => ({ ...state, [prop]: [ ...state[prop], ...value ] }));
        } else {
            if ( isNil(id) ) {
                setProject(state => ({ ...state, [prop]: [ ...state[prop], ...value.map(o => ({ file_id: o.id, filename: o.name, size: o.size, url: o.url })) ] }));
            } else {
                setProject(state => ({ ...state, [prop]: [ ...state[prop].filter(o => o.file_id !== id), ...value ] }));
            }
        }
    };
    const onRemoveFile = (prop, value) => {
        setProject(state => ({ ...state, [prop]: value }));
    };

    /* Hooks */
    const { invalidFields, addField, removeField, setInvalidFields } = useInvalidFields();
    const { progress, onFormDrop, onFormDragStart, getDropzoneHtml } = useDropzoneEl(attachments, files, onUpdateFiles, onRemoveFile, job_type === 'design' ? maxSize1Gb : maxSize, invalidFields, removeField, job_type, '', 'files', 'attachments', true);
    const { progress: motionProgress, getDropzoneMotion } = useDropzoneEl(attachments, logo_file, onUpdateFiles, onRemoveFile, maxSize1Gb, invalidFields, removeField, job_type, '', 'logo_file', 'attachments');
    const { getDropzoneHtml: getMotionIdeasDropzoneHtml } = useDropzoneEl(idea_attachments, idea_files, onUpdateFiles, onRemoveFile, maxSize1Gb, invalidFields, removeField, job_type, '', 'idea_files', 'idea_attachments', true);
    const { details, checkTopicAsync, updateProjectAsync } = useProject();
    const { account_uid } = details;
    const { inviteTalentsObj, setModal, setModalState } = useModals();
    const { staticData: { industries, countries, localizations, formats, copy_styles, copy_voices, target_audiences, content_types, file_types: fileTypesObj, cms: cmsData, }} = useLists();
    const { shouldEditBrief, brief_links_shared, isMobile, isEmptyWriters, isTopicUnique, setUiState, checkBriefAsync } = useUi();
    const { brandProfiles, setUserState, fetchTalentsAsync } = useUser();
    const navigate = useNavigate();

    useLayoutEffect(() => {
        document.title = getAppTitle('Project brief', company);

        if ( !isEmpty(details) ) {
            onSetProjectState(details);
        }
        if ( shouldEditBrief ) {
            setIsEditing(true);
        }

        // conts handleBeforeUnload = (event) => {
        //     const { isEditing } = this.state;
        //     if ( isEditing ) {
        //         event.returnValue = 'You have unsaved changes on this page. Are you sure you want to leave this page and discard unsaved changes?';
        //         return event.returnValue;
        //     }
        // };
        // window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            setUiState('shouldEditBrief',false);
            setUiState('brief_links_shared', 'yes');
            setModalState('inviteTalentsObj', null);
            //window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    useLayoutEffect(() => {
        if ( isEditing ) {
            initRedactors();
        }
    }, [isEditing]);
    useEffect(() => {
        const onOutsideClick = (e) => {
            onOutsideElClick(e,noWritersRef.current,() => { setUiState('isEmptyWriters', false) });
        };

        if ( isEmptyWriters ) {
            document.addEventListener('click', onOutsideClick, true);
            document.addEventListener('touchstart', onOutsideClick, true);
        }

        return () => {
            document.removeEventListener('click', onOutsideClick, true);
            document.removeEventListener('touchstart', onOutsideClick, true);
        };
    }, [isEmptyWriters]);
    useEffect(() => {
        if ( brief_links_shared === 'no' ) {
            addField('brief');
        }
    }, [brief_links_shared]);
    useEffect(() => {
        if ( prevImageOption !== 'browse-images' && prevImageOption !== 'find-images-for-me' ) {
            if ( image_option === 'browse-images' || image_option === 'find-images-for-me' ) {
                initDefaultRedactor('#image_notes-textarea', 'image_notes', '116px', null, onRedactorSynced, onRedactorBlur, true);
            }
        }
    }, [image_option]);
    useEffect(() => {
        setProject(state => ({ ...state, 'image_option_files': details.image_option_files }));
    }, [details.image_option_files]);
    useEffect(() => {
        const profiles = brandProfiles.filter(o => !isNil(brand_profiles) && includes(o.id, brand_profiles));
        if ( !isEmpty(profiles) && profiles.every(o => !o.active) ) {
            setProject(state => ({ ...state, 'brand_profiles': [] }));
            if ( !isEditing ) {
                setIsEditing(true);
            }
        }
    }, [brandProfiles]);
    useEffect(() => {
        if ( !isNil(inviteTalentsObj) ) {
            setProject(state => ({ ...state, ...inviteTalentsObj }));
        }
    }, [inviteTalentsObj]);

    if ( isEmpty(project) ) return null;

    /* Actions */
    const onRedactorSynced = (data, prop) => {
        setProject(state => ({ ...state, [prop]: data }));
        removeField(prop);

        if ( brief_links_shared === 'no' && prop === 'brief' ) {
            setUiState('brief_links_shared', 'yes');
        }
    };
    const onRedactorBlur = (prop) => {
        if ( prop === 'brief' ) {
            if (!isEmpty(clearBrief(brief)) && brief_links_shared === 'yes') {
                checkBriefAsync(brief);
            }
            if ( clearBrief(brief).length > 30000 ) {
                setIsBriefLimit(true);
            }
        }
    };
    const initRedactors = () => {
        if ( job_type === 'design' ) {
            initDefaultRedactor('#brief-textarea', 'brief', '116px', null, onRedactorSynced, onRedactorBlur, true);
            initDefaultRedactor('#included_text-textarea', 'included_text', '116px', null, onRedactorSynced, onRedactorBlur, true);
            initDefaultRedactor('#design_references-textarea', 'design_references', '116px', null, onRedactorSynced, onRedactorBlur, true);
            if ( image_option === 'browse-images' || image_option === 'find-images-for-me' ) {
                initDefaultRedactor('#image_notes-textarea', 'image_notes', '116px', null, onRedactorSynced, onRedactorBlur, true);
            }
            if ( `${format}` === '28' ) {
                initDefaultRedactor('#format_description-textarea', 'format_description', '116px', null, onRedactorSynced, onRedactorBlur, true);
                initDefaultRedactor('#format_reason-textarea', 'format_reason', '116px', null, onRedactorSynced, onRedactorBlur, true);
                initDefaultRedactor('#format_target_audience-textarea', 'format_target_audience', '116px', null, onRedactorSynced, onRedactorBlur, true);
            }
        } else if ( job_type === 'keywords' ) {
            initDefaultRedactor('#brief-textarea', 'brief', '116px', null, onRedactorSynced, onRedactorBlur, true);
            initDefaultRedactor('#competitors-textarea', 'competitors', '116px', null, onRedactorSynced, onRedactorBlur, true);
            initDefaultRedactor('#additional_details-textarea', 'additional_details', '116px', null, onRedactorSynced, onRedactorBlur, true);
        } else if ( job_type === 'ideas' ) {
            initDefaultRedactor('#brief-textarea', 'brief', '116px', null, onRedactorSynced, onRedactorBlur, true);
            initDefaultRedactor('#product_service-textarea', 'product_service', null, null, onRedactorSynced, onRedactorBlur, true);
        } else if ( job_type === 'motion' ) {
            initDefaultRedactor('#brand_description-textarea', 'brand_description', '116px', null, onRedactorSynced, onRedactorBlur, true);
            initDefaultRedactor('#ideas-textarea', 'ideas', '116px', null, onRedactorSynced, onRedactorBlur, true);
            initDefaultRedactor('#examples-textarea', 'examples', '116px', null, onRedactorSynced, onRedactorBlur, true);
        } else if ( job_type === 'webaudit' ) {
            initDefaultRedactor('#products-textarea', 'products', '116px', null, onRedactorSynced, onRedactorBlur, true);
            initDefaultRedactor('#competitors-textarea', 'competitors', '116px', null, onRedactorSynced, onRedactorBlur, true);
            initDefaultRedactor('#target_audience-textarea', 'target_audience', '116px', null, onRedactorSynced, onRedactorBlur, true);
            initDefaultRedactor('#goals-textarea', 'goals', '116px', null, onRedactorSynced, onRedactorBlur, true);
            initDefaultRedactor('#notes-textarea', 'notes', '116px', null, onRedactorSynced, onRedactorBlur, true);
        } else if ( job_type === 'seoaudit' ) {
            initDefaultRedactor('#competitors-textarea', 'competitors', '116px', null, onRedactorSynced, onRedactorBlur, true);
            initDefaultRedactor('#target_audience-textarea', 'target_audience', '116px', null, onRedactorSynced, onRedactorBlur, true);
            initDefaultRedactor('#goals-textarea', 'goals', '116px', null, onRedactorSynced, onRedactorBlur, true);
            if ( seo_type === 'technical' ) {
                initDefaultRedactor('#focus_areas-textarea', 'focus_areas', '116px', null, onRedactorSynced, onRedactorBlur, true);
                initDefaultRedactor('#monitoring_tools-textarea', 'monitoring_tools', '116px', null, onRedactorSynced, onRedactorBlur, true);
            } else if ( seo_type === 'competitor' ) {
                initDefaultRedactor('#seo_strategies-textarea', 'seo_strategies', '116px', null, onRedactorSynced, onRedactorBlur, true);
                initDefaultRedactor('#keywords-textarea', 'keywords', '116px', null, onRedactorSynced, onRedactorBlur, true);
                initDefaultRedactor('#keywords_google-textarea', 'keywords_google', '116px', null, onRedactorSynced, onRedactorBlur, true);
            } else if ( seo_type === 'full' ) {
                initDefaultRedactor('#seo_audit_notes-textarea', 'seo_audit_notes', '116px', null, onRedactorSynced, onRedactorBlur, true);
                initDefaultRedactor('#focus_areas-textarea', 'focus_areas', '116px', null, onRedactorSynced, onRedactorBlur, true);
                initDefaultRedactor('#keywords_google-textarea', 'keywords_google', '116px', null, onRedactorSynced, onRedactorBlur, true);
                initDefaultRedactor('#marketing_channels-textarea', 'marketing_channels', '116px', null, onRedactorSynced, onRedactorBlur, true);
                initDefaultRedactor('#monitoring_tools-textarea', 'monitoring_tools', '116px', null, onRedactorSynced, onRedactorBlur, true);
            }
        } else if ( job_type === 'seobrief' ) {
            initDefaultRedactor('#brief-textarea', 'brief', '116px', null, onRedactorSynced, onRedactorBlur, true);
            initDefaultRedactor('#additional_details-textarea', 'details', '116px', null, onRedactorSynced, onRedactorBlur, true);
        } else if ( job_type === 'contentstrategy' ) {
            initDefaultRedactor('#brief-textarea', 'brief', '116px', null, onRedactorSynced, onRedactorBlur, true);
            initDefaultRedactor('#additional_details-textarea', 'details', '116px', null, onRedactorSynced, onRedactorBlur, true);
        }
        else {
            initDefaultRedactor('#brief-textarea', 'brief', '116px', null, onRedactorSynced, onRedactorBlur, true);
        }
    };

    const onEditBrief = () => {
        setIsEditing(true);
    };
    const onInputBlur = (e) => {
        let { currentTarget: { id:prop, value }} = e;
        value = removeScriptTags(value);

        if ( prop === 'topic' && !isEmpty(value) && isTopicUnique ) {
            checkTopicAsync(value);
        }

        setProject(state => ({ ...state, [prop]: value }));
    };
    const onInputFieldChange = (e) => {
        const { currentTarget: { id:prop, value }} = e;
        e.persist();

        if ( value ) {
            removeField(prop);
        }
        if (prop === 'topic' && !isTopicUnique) {
            setUiState('isTopicUnique', true);
        }

        setProject(state => ({ ...state, [prop]: value }));
    };
    const onFormFieldChange = (prop, value) => {
        let data = value;
        // if ( prop === 'target_country_code' ) {
        //     data = data.toUpperCase();
        // }
        if ( data ) {
            removeField(prop);
        }
        setProject(state => ({ ...state, [prop]: data }));
    };
    // const onRadioFieldChange = ({ currentTarget: { dataset: { prop, value }}}) => {
    //     if ( value ) {
    //         removeField(prop);
    //     }
    //     setProject(state => ({ ...state, [prop]: value }));
    // };
    const onBrandProfileEdit = (e) => {
        e.stopPropagation();
        const { currentTarget: { dataset: { id }}} = e;

        setUserState('activeBrandProfile', brandProfiles.filter(o => `${o.id}` === `${id}`)[0]);
        setModal('createBrandProfile');
    };
    const onBrandProfileRadioClick = ({ currentTarget: { dataset: { value }}}) => {
        const profiles = isNil(brand_profiles) ? [] : brand_profiles;

        if ( value === 'create-new-profile' ) {
            setModal('createBrandProfile');
            return;
        }
        const valueAsNumber = Number(value);
        if ( includes(valueAsNumber, profiles) ) {
            setProject(state => ({ ...state, 'brand_profiles': profiles.filter(o => o !== valueAsNumber) }));
        } else {
            setProject(state => ({ ...state, 'brand_profiles': [ ...profiles, valueAsNumber ] }));
        }
        removeField('brand_profiles');
    };
    const onFileTypesChange = ({ currentTarget: { dataset: { prop, value }}}) => {
        const list = ['fig','ai','psd','indd','ppt','key','gslides','gif','mp4','mov','ae','webm','lottie'];
        let data = includes(value, project[prop]) ? project[prop].filter(item => item !== value) : [...project[prop], value];

        if ( value ) {
            removeField(prop);
        }
        if ( prop === 'file_types' && includes(value, list) ) {
            const shortList = list.filter(item => item !== value);
            const shortData = project[prop].filter(item => !includes(item, shortList));
            data = includes(value, project[prop]) ? project[prop].filter(item => item !== value) : [...shortData, value];
        }
        setProject(state => ({ ...state, [prop]: data }));
    };
    const onImageFilesClick = ({ currentTarget: { dataset: { url }}}) => {
        setModal('fullImage');
        setModalState('fullImageUrl', url);
    };
    const onImageFilesRemove = ({ currentTarget: { dataset: { id }}}) => {
        const data = image_option_files.filter(o => o.file_id !== id);
        setProject(state => ({ ...state, image_option: isEmpty(data) ? '' : image_option, image_option_files: data }));
    };
    const onDesignImagesRadioClick = ({ currentTarget: { dataset: { value }}}) => {
        if ( image_option !== value ) {
            removeField('image_option');

            if ( value === 'browse-images' ) {
                setModal('images');
            }
            setProject(state => ({ ...state, image_option: value, image_notes: '', image_option_files: [] }));
        }
    };
    const onUsedServicesChange = ({ currentTarget: { dataset: { value }}}) => {
        const services = isNil(used_services) ? [] : used_services;

        setProject(state => ({ ...state, 'used_services': includes(value, services) ? services.filter(el => el !== value) : [...services, value], file_types: [] }));
    };
    const onWorkflowRadioClick = ({ currentTarget: { dataset: { value }}}) => {
        const workflowData = isNil(workflow) ? [] : workflow;

        setProject(state => ({ ...state, workflow: includes(value, workflowData) ? workflowData.filter(o => o !== value) : [...workflowData, value] }));
    };

    /* Writers */
    const onMouseOver = () => {
        const list = writersListRef.current;
        const rect = list.getBoundingClientRect();
        if ( rect.top < 112 ) {
            const offset = list.dataset.offset;
            list.style.top = 'auto';
            list.style.bottom = offset;
            list.classList.add('bottom');
        }
    };
    const onMouseOut = () => {
        const list = writersListRef.current;
        const offset = list.dataset.offset;
        list.style.bottom = 'auto';
        list.style.top = offset;
        list.classList.remove('bottom');
    };
    const generateWriters = () => {
        const writers = invite_writers ? invite_writers : [];
        const writersData = writers.map(({ writer_uid, writer_avatar_url = '', writer_first_name, writer_last_name }) => {
            return <div key = { writer_uid } className = 'gac-writer-item gac-active'>
                { writer_avatar_url
                    ? <img src = { writer_avatar_url } alt = { `${writer_first_name.charAt(0)}${writer_last_name} avatar` }/>
                    : `${writer_first_name.charAt(0)}${writer_last_name}` }
                <div className="gac-writer-name">{ writer_first_name } { writer_last_name }</div>
            </div>;
        });
        const writersFirst = writersData.filter((o,i) => i <= 4);
        const rest = writers.filter((item, i) => i > 4 && i < 15).map((item, i) => <div key = { i }>{ `${item.writer_first_name} ${item.writer_last_name}` }</div> );

        return <>
            { writersData.length
                ? writersData.length > 5
                    ? <>
                        { writersFirst }
                        <div className = 'gac-writers-rest' onMouseOver = { onMouseOver } onMouseOut = { onMouseOut }>
                            { `+${writersData.length - 5}` }
                            <div
                                data-offset = { `-${40 + 22*(rest.length - (writersData.length > 15 ? 0 : 1))}px` }
                                className = "gac-writers-rest-list"
                                ref = { writersListRef }
                                style = {{ top: `-${40 + 22*(rest.length - (writersData.length > 15 ? 0 : 1))}px` }}>
                                { rest }
                                { writersData.length > 15 && <div>{ `+ ${writersData.length - 15} more` }</div> }
                            </div>
                        </div>
                    </>
                    : writersData
                : <>
                    <div className = 'gac-writer-item'/>
                    <div className = 'gac-writer-item'/>
                    <div className = 'gac-writer-item'/>
                </> }
            <span className = 'gac-writers-plus'>+</span>
        </> ;
    };

    const onSeoBriefTypeChange = ({ currentTarget: { dataset: { prop, value } } }) => {
        if ( value ) {
            removeField(prop);
        }

        setProject(state => ({ ...state, [prop]: value }));
    };

    const showModalWriters = () => {
        fetchTalentsAsync({ type: includes(job_type, 'design/motion') ? 'designers' : 'copywriters', page: 'brief' });
        setModalState('modalTalentsData', { invite_writers, invite_writer_active_for, level, job_type });
    };
    const inviteAllWriters = () => {
        setProject(state => ({ ...state, invite_writers: [], invite_writer_active_for: 0, }));
    };

    /* Submit */
    const validateFields = () => {
        let fields = [];

        if ( topic === '' || topic === ' ' || topic.length > 150 ) {
            fields.push('topic');
        }
        if ( !isEmpty(progress) || (job_type === 'motion' && !isEmpty(motionProgress) ) ) {
            fields.push('attachment');
        }
        if ( job_type === 'design' ) {
            if ( isEmpty(clearBrief(brief)) || isBriefLimit ) {
                fields.push('brief');
            }
            if ( isEmpty(file_types) ) {
                fields.push('file_types');
            }
            if ( `${format}` === '28' ) {
                if ( isEmpty(clearBrief(format_description)) ) {
                    fields.push('format_description');
                }
                if ( isEmpty(clearBrief(format_reason)) ) {
                    fields.push('format_reason');
                }
                if ( isEmpty(clearBrief(format_target_audience)) ) {
                    fields.push('format_target_audience');
                }
            }
        } else if ( job_type === 'seoaudit' ) {
            if ( website === '' || website === ' ' ) {
                fields.push('website');
            }
            if ( isEmpty(clearBrief(competitors)) ) {
                fields.push('competitors');
            }
            if ( isEmpty(clearBrief(target_audience)) ) {
                fields.push('target_audience');
            }
            if ( isEmpty(clearBrief(goals)) ) {
                fields.push('goals');
            }

            if ( seo_type === 'technical' ) {
                if ( isEmpty(clearBrief(focus_areas)) ) {
                    fields.push('focus_areas');
                }
                if ( isEmpty(clearBrief(monitoring_tools)) ) {
                    fields.push('monitoring_tools');
                }
            } else if ( seo_type === 'competitor' ) {
                if ( isEmpty(clearBrief(seo_strategies)) ) {
                    fields.push('seo_strategies');
                }
                if ( isEmpty(clearBrief(keywords)) ) {
                    fields.push('keywords');
                }
                if ( isEmpty(clearBrief(keywords_google)) ) {
                    fields.push('keywords_google');
                }
            } else if ( seo_type === 'full' ) {
                if ( isEmpty(clearBrief(seo_audit_notes)) ) {
                    fields.push('seo_audit_notes');
                }
                if ( isEmpty(clearBrief(focus_areas)) ) {
                    fields.push('focus_areas');
                }
                if ( isEmpty(clearBrief(keywords_google)) ) {
                    fields.push('keywords_google');
                }
                if ( isEmpty(clearBrief(marketing_channels)) ) {
                    fields.push('marketing_channels');
                }
                if ( isEmpty(clearBrief(monitoring_tools)) ) {
                    fields.push('monitoring_tools');
                }
            }
        } else if ( job_type === 'webaudit' ) {
            if ( website === '' || website === ' ' ) {
                fields.push('website');
            }
            if ( isEmpty(clearBrief(link_to_resources)) ) {
                fields.push('link_to_resources');
            }
            if ( isEmpty(clearBrief(products)) ) {
                fields.push('products');
            }
            if ( isEmpty(clearBrief(competitors)) ) {
                fields.push('competitors');
            }
            if ( isEmpty(clearBrief(target_audience)) ) {
                fields.push('target_audience');
            }
            if ( isEmpty(clearBrief(goals)) ) {
                fields.push('goals');
            }
        } else if ( job_type === 'motion' ) {
            if ( isEmpty(clearBrief(ideas)) ) {
                fields.push('ideas');
            }
            if ( isEmpty(clearBrief(examples)) ) {
                fields.push('examples');
            }
            if ( isEmpty(clearBrief(animationLength)) ) {
                fields.push('length');
            }
        } else if ( job_type === 'keywords' ) {
            if ( isEmpty(clearBrief(brief)) || isBriefLimit ) {
                fields.push('brief');
            }
            if ( target_audience === '' || target_audience === ' ' ) {
                fields.push('target_audience');
            }
            if ( isEmpty(clearBrief(competitors)) ) {
                fields.push('competitors');
            }
            if ( target_location === '' ) {
                fields.push('target_location');
            }
        } else if ( job_type === 'ideas' ) {
            if ( isEmpty(clearBrief(product_service)) ) {
                fields.push('product_service');
            }
            if ( target_audience === '' || target_audience === ' ' ) {
                fields.push('target_audience');
            }
            if ( content_goal === '' || content_goal === ' ' ) {
                fields.push('content_goal');
            }
            if ( isEmpty(clearBrief(brief)) || brief_links_shared === 'no' || isBriefLimit ) {
                fields.push('brief');
            }
            if ( word_count === '' || word_count === ' ' ) {
                fields.push('word_count');
            }
        } else if ( job_type === 'seobrief' ) {
            if ( website === '') {
                fields.push('website');
            }
            if ( isEmpty(clearBrief(brief)) || brief_links_shared === 'no' || isBriefLimit ) {
                fields.push('brief');
            }
            if ( competitors === '') {
                fields.push('competitors');
            }
            if ( target_location === '') {
                fields.push('target_location');
            }
            if ( target_keywords === '') {
                fields.push('target_keywords');
            }
        } else if ( job_type === 'contentstrategy' ) {
            if ( website === '') {
                fields.push('website');
            }
            if ( isEmpty(clearBrief(brief)) || brief_links_shared === 'no' || isBriefLimit ) {
                fields.push('brief');
            }
            if ( competitors === '') {
                fields.push('competitors');
            }
            if ( target_location === '') {
                fields.push('target_location');
            }
            if ( target_keywords === '') {
                fields.push('target_keywords');
            }
        } else {
            if ( isEmpty(clearBrief(brief)) || brief_links_shared === 'no' || isBriefLimit ) {
                fields.push('brief');
            }
            if ( !localization ) {
                fields.push('localization');
            }
            if ( industry === 'other' && ( isNil(other_industry) || other_industry === '' || other_industry === ' ')) {
                fields.push('other_industry');
            }
        }

        return fields;
    };
    const onSubmit = () => {
        const fields = validateFields();

        setInvalidFields(fields);
        if ( isEmpty(fields) ) {
            setIsEditing(false);

            window.$R('#brief-textarea', 'destroy');
            let obj = {};
            if ( job_type === 'keywords' ) {
                window.$R('#competitors-textarea', 'destroy');
                window.$R('#details-textarea', 'destroy');
            } else if ( job_type === 'ideas' ) {
                window.$R('#product_service-textarea', 'destroy');
            } else if ( job_type === 'contentstrategy' ) {
                window.$R('#additional_details-textarea', 'destroy');
            } else if ( job_type === 'design' ) {
                let imageOptionFiles = null;
                if ( project.image_option === 'browse-images' ) {
                    imageOptionFiles = project.image_option_files.map(({file_id}) => file_id);
                }
                obj = {
                    ...obj,
                    image_option_files: imageOptionFiles,
                }
            }

            let writers = invite_writers ? invite_writers.map(({ writer_uid }) => writer_uid) : [];
            if ( !invite_writers || !invite_writers.length ) {
                delete project.invite_writer_active_for;
            }

            updateProjectAsync({
                ...project,
                files: job_type === 'motion' || isEmpty(attachments) ? '' : attachments.map(o => o.file_id).join(','),
                logo_file: job_type !== 'motion' || isEmpty(attachments) ? '' : attachments.map(o => o.file_id).join(','),
                project_tags: JSON.parse(project_tags),
                format_options: pay_type === 'one-off' && !isNil(format_options) ? format_options.map(o => JSON.stringify(o)) : null,
                invite_writers: writers,
                ...obj
            }, { name: 'update_project', job_type }, null, navigate);
        } else {
            animateScrollTo(document.getElementById(fields[0]), {
                verticalOffset: -100,
            });
        }
    };

    /* Fields */
    const getFilesHtml = (data) => {
        const filesData = (data && data.length)
            ? data.map(({ file_id, filename, size, url }) => {
                return <a href={url} target='_blank' rel = 'noopener noreferrer' key = { file_id } className = 'gac-project-file'>
                    <i className = { `gac-project-file-type ${ filename.split('.').slice(-1)[0] }` }/>
                    <span title = { filename } className = 'gac-project-file-name'>{ filename }</span>
                    <div className = 'gac-align-right'>
                        <span className = 'gac-project-file-size'>{ bytesToSize(size) }</span>
                    </div>
                </a>;
            })
            : null;

        if ( isNil(filesData) ) return null;

        return <div className="gac-project-files">{ filesData }</div> ;
    };
    const getIndustryField = () => {
        const industriesData = getTitle(industries, industry);

        if ( isEditing ) {
            return(
                <div className = {`gac-form-row gac-row-multiple ${ industry === 'other' ? 'gac-other-industry' : 'gac-industry' }`}>
                    <div className = 'gac-form-field gac-field-1-2'>
                        <span className = 'gac-form-label'>Industry</span>
                        <Select
                            isMultiple = { false }
                            items = { industries }
                            value = { industry ? industry : '' }
                            title = { industriesData }
                            parameter = 'industry'
                            invalid = { invalidFields.includes('industry') }
                            onChange = { onFormFieldChange }/>
                    </div>
                    { industry === 'other' && <div className = 'gac-form-field gac-field-1-2'>
                        <span className = 'gac-form-label'>Industry name</span>
                        <div className = 'gac-form-input'>
                            <input
                                className = { invalidFields.includes('other_industry') ? 'gac-invalid' : '' }
                                id = 'other_industry'
                                data-hj-whitelist
                                value = { other_industry }
                                type = 'text'
                                onBlur = { onInputBlur }
                                onChange = { onInputFieldChange }/>
                        </div>
                    </div> }
                </div>
            );
        }

        return <div className="gac-brief-field">
            <div className="gac-brief-label">Industry:</div>
            <div className="gac-brief-data">{ industriesData }{ industry === 'other' ? ` / ${other_industry}` : '' }</div>
        </div> ;
    };
    const getTopicField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>{ includes(job_type, 'ideas/keywords/seobrief/contentstrategy') ? 'Website' : includes(job_type, 'design/motion') ? 'Project name' : 'Topic' }</span>
                    <div className = 'gac-form-input'>
                        <input
                            className = { invalidFields.includes('topic') ? 'gac-invalid' : '' }
                            id = 'topic'
                            data-hj-whitelist
                            value = { topic }
                            type = 'text'
                            onBlur = { onInputBlur }
                            onChange = { onInputFieldChange }/>
                        { !isTopicUnique && <div className = 'gac-warning'>{ 'You’ve ordered this before' }</div> }
                        { invalidFields.includes('topic') && topic.length > 150 && <div className = 'gac-error'>Please limit topic to 150 characters</div> }
                    </div>
                </div>
            </div> ;
        }

        return null;
    };
    const getContentField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Content</span>
                    <div className='gac-form-content'>
                        <div className="gac-form-content-box">
                            <div className="gac-form-content-in" dangerouslySetInnerHTML = {{__html: content}}/>
                        </div>
                    </div>
                </div>
            </div>;
        }

        return(
            <div className="gac-brief-field">
                <div className="gac-brief-label">Content:</div>
                <div className="gac-brief-data">
                    <div className="gac-brief-content" dangerouslySetInnerHTML={{__html: content}}/>
                </div>
            </div>
        );
    };
    const getBriefField = () => {
        if (isEditing) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Brief</span>
                    <div id = 'brief' className = { `gac-form-textarea ${invalidFields.includes('brief') || isBriefLimit ? 'gac-invalid' : ''}` }>
                        <textarea id = 'brief-textarea' value = { brief } readOnly />
                    </div>
                </div>
                { getDropzoneHtml() }
                { brief_links_shared === 'no' && <div className = 'gac-error'>The link sharing should be set to 'Anyone with the link' or 'Public on the web'</div> }
                { isBriefLimit && <div className = 'gac-error'>Please limit brief to 30,000 characters</div> }
            </div>;
        }

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Brief:</span> }
            <div className="gac-brief-data gac-with-files">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{ __html: setLinksTarget(brief) }}/>
                { getFilesHtml(files) }
            </div>
        </div> ;
    };
    const getServicesField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Primary services or products</span>
                    <div id = 'brief' className = { `gac-form-textarea ${invalidFields.includes('brief') || isBriefLimit ? 'gac-invalid' : ''}` }>
                        <textarea id = 'brief-textarea' value = { brief } readOnly />
                    </div>
                </div>
                { isBriefLimit && <div className = 'gac-error'>Please limit brief to 30,000 characters</div> }
            </div> ;
        }

        return <div className="gac-brief-field">
            <div className="gac-brief-label">Primary services or products:</div>
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{ __html: setLinksTarget(brief) }}/>
            </div>
        </div> ;
    };
    const getTargetAudienceField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Target audience</span>
                    <div className = 'gac-form-input'>
                        <input
                            className = { invalidFields.includes('target_audience') ? 'gac-invalid' : '' }
                            id = 'target_audience'
                            data-hj-whitelist
                            value = { target_audience }
                            type = 'text'
                            onBlur = { onInputBlur }
                            onChange = { onInputFieldChange }/>
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            <div className="gac-brief-label">Target audience:</div>
            <div className="gac-brief-data">{ target_audience }</div>
        </div> ;
    };
    const getContentGoalField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Content goal</span>
                    <div className = 'gac-form-input'>
                        <input
                            className = { invalidFields.includes('content_goal') ? 'gac-invalid' : '' }
                            id = 'content_goal'
                            data-hj-whitelist
                            value = { content_goal }
                            type = 'text'
                            onBlur = { onInputBlur }
                            onChange = { onInputFieldChange }/>
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            <div className="gac-brief-label">Content goal:</div>
            <div className="gac-brief-data">{ content_goal }</div>
        </div> ;
    };
    const getProductServiceField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Target product/service or keywords</span>
                    <div id = 'product_service' className = { `gac-form-textarea ${invalidFields.includes('product_service') ? 'gac-invalid' : ''}` }>
                        <textarea id = 'product_service-textarea' value = { product_service } readOnly />
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            <div className="gac-brief-label">Target product/service or keywords:</div>
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{ __html: setLinksTarget(product_service) }}/>
            </div>
        </div> ;
    };
    const getCompetitorsField = () => {
        const label = job_type === 'webaudit' ? 'List your 3 main competitors' : 'Main competitor websites';
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>{ label }</span>
                    <div id = 'competitors' className = { `gac-form-textarea ${invalidFields.includes('competitors') ? 'gac-invalid' : ''}` }>
                        <textarea id = 'competitors-textarea' value = { competitors } readOnly />
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            <div className="gac-brief-label">{ label }:</div>
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{ __html: setLinksTarget(competitors) }}/>
            </div>
        </div> ;
    };
    const getTargetCountryField = () => {
        const countriesData = countries.filter((o, i) => i < 6).map(o => ({ value: o.code, title: o.name }));
        const titleTargetCountry = getTitle(countriesData, target_country_code);

        if ( isEditing ) {
            return <div className = 'gac-form-row gac-row-multiple'>
                <div className = 'gac-form-field gac-field-1-2'>
                    <span className = 'gac-form-label'>Target country</span>
                    <Select
                        isMultiple = { false }
                        items = { countriesData }
                        value = { target_country_code ? target_country_code : '' }
                        title = { titleTargetCountry }
                        parameter = 'target_country_code'
                        onChange = { onFormFieldChange }/>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            <div className="gac-brief-label">Target country:</div>
            <div className="gac-brief-data">{ titleTargetCountry }</div>
        </div> ;
    };
    const getTargetLocationField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Target suburb, city, state, or country</span>
                    <div className = 'gac-form-input'>
                        <input
                            className = { invalidFields.includes('target_location') ? 'gac-invalid' : '' }
                            id = 'target_location'
                            data-hj-whitelist
                            value = { target_location }
                            type = 'text'
                            onBlur = { onInputBlur }
                            onChange = { onInputFieldChange }/>
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            <div className="gac-brief-label">Target suburb, city, state, or country:</div>
            <div className="gac-brief-data">{ target_location }</div>
        </div> ;
    };
    const getAdditionalDetailsField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Additional details</span>
                    <div id = 'additional_details' className = 'gac-form-textarea'>
                        <textarea id = 'additional_details-textarea' value = { additional_details } readOnly />
                    </div>
                </div>
                { getDropzoneHtml() }
            </div> ;
        }

        return <div className="gac-brief-field">
            <div className="gac-brief-label">Additional details:</div>
            <div className="gac-brief-data gac-with-files">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{ __html: setLinksTarget(additional_details) }}/>
                { getFilesHtml(files) }
            </div>
        </div> ;
    };
    const getWritersField = () => {
        let writers = invite_writers ? invite_writers : [];
        const isDesign = includes(job_type, 'design/motion');

        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Invite { isDesign ? 'designers' : 'writers' }</span>
                    <div className = 'gac-invite-writers'>
                        <span onClick = { inviteAllWriters } className = { `gac-writers-all ${!writers.length ? 'gac-active' : '' }` }>All</span>
                        <span className = 'gac-writers-label'>or</span>
                        <div onClick = { showModalWriters } className = 'gac-writers-list'>
                            { generateWriters() }
                            { (isEmptyWriters && (isNil(invite_writers) || isEmpty(invite_writers))) && <div ref = { noWritersRef } className = 'gac-no-writers'>No writers on your team yet</div> }
                        </div>
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field gac-field-writers">
            <div className="gac-brief-label">Invite { isDesign ? 'designers' : 'writers' }:</div>
            <div className="gac-brief-data" style={{ overflow: 'visible' }}>
                <div className="gac-invite-writers">
                    <div className = 'gac-writers-list'>
                        { writers.length ? generateWriters() : <div className="gac-writers-all gac-active">All</div> }
                    </div>
                </div>
            </div>
        </div> ;
    };
    const getLocalizationField = () => {
        const localizationData = getTitle(localizations, localization);
        let formatTitle = '';
        if ( job_type === 'ideas' ) {
            formatTitle = getFormat(formats['writing'], format);
        }

        if ( isEditing ) {
            return <div className = 'gac-form-row gac-row-multiple'>
                { job_type === 'ideas' && <div className = 'gac-form-field gac-field-1-2'>
                    <span className = 'gac-form-label'>{`Planned word count (for each ${formatTitle.toLowerCase()})`}</span>
                    <div className = 'gac-form-input'>
                        <input
                            id = 'word_count'
                            className = { invalidFields.includes('word_count') ? 'gac-invalid' : '' }
                            value = { word_count }
                            type = 'text'
                            data-hj-whitelist
                            onBlur = { onInputBlur }
                            onChange = { onInputFieldChange }/>
                    </div>
                </div> }
                <div className = 'gac-form-field gac-field-1-2'>
                    <span className = 'gac-form-label'>Localization</span>
                    <Select
                        isMultiple = { false }
                        items = { localizations }
                        value = { localization ? localization : '' }
                        title = { localizationData }
                        parameter = 'localization'
                        invalid = { invalidFields.includes('localization') }
                        onChange = { onFormFieldChange }/>
                </div>
            </div> ;
        }

        return <>
            <div className="gac-brief-field">
                <div className="gac-brief-label">Localization:</div>
                <div className="gac-brief-data">{ localizationData }</div>
            </div>
            { job_type === 'ideas' && <div className="gac-brief-field">
                <div className="gac-brief-label">{`Planned word count (for each ${formatTitle.toLowerCase()}):`}</div>
                <div className="gac-brief-data">{ word_count }</div>
            </div> }
        </> ;
    };
    const getCopyStyleVoiceField = () => {
        const copyStyle = getTitle(copy_styles, copy_style);
        const copyVoice = getTitle(copy_voices, copy_voice);

        if ( isEditing ) {
            return <div className = 'gac-form-row gac-row-multiple'>
                <div className = 'gac-form-field gac-field-1-2'>
                    <span className = 'gac-form-label'>Copy style</span>
                    <Select
                        isMultiple = { false }
                        items = { copy_styles }
                        value = { copy_style ? copy_style : '' }
                        title = { copyStyle }
                        parameter = 'copy_style'
                        onChange = { onFormFieldChange }/>
                </div>
                <div className = 'gac-form-field gac-field-1-2'>
                    <span className = 'gac-form-label'>Copy voice</span>
                    <Select
                        isMultiple = { false }
                        items = { copy_voices }
                        value = { copy_voice ? copy_voice : '' }
                        title = { copyVoice }
                        parameter = 'copy_voice'
                        onChange = { onFormFieldChange }/>
                </div>
            </div> ;
        }

        return <>
            <div className="gac-brief-field">
                <div className="gac-brief-label">Copy style:</div>
                <div className="gac-brief-data">{ copyStyle }</div>
            </div>
            <div className="gac-brief-field">
                <div className="gac-brief-label">Copy voice:</div>
                <div className="gac-brief-data">{ copyVoice }</div>
            </div>
        </> ;
    };
    const getAdditionalFields = () => {
        const targetAudiences = getTitle(target_audiences, target_audience);
        const contentData = getTitle(content_types, content);

        if ( isEditing ) {
            return <>
                <div className = 'gac-form-row gac-row-multiple'>
                    <div className='gac-form-field gac-field-1-2'>
                        <span className='gac-form-label'>Target audience</span>
                        <Select
                            isMultiple = { false }
                            items = { target_audiences }
                            value = { target_audience ? target_audience : ''}
                            title = { targetAudiences }
                            parameter = 'target_audience'
                            onChange = { onFormFieldChange }/>
                    </div>
                    <div className = 'gac-form-field gac-field-1-2'>
                        <span className = 'gac-form-label'>Content</span>
                        <Select
                            isMultiple = { false }
                            items = { content_types }
                            value = { content ? content : '' }
                            title = { contentData }
                            parameter = 'content'
                            onChange = { onFormFieldChange }/>
                    </div>
                </div>
                <div className = 'gac-form-row'>
                    <div className = 'gac-form-field'>
                        <span className = 'gac-form-label'>Mention</span>
                        <div className = 'gac-form-input'>
                            <input
                                id = 'mention'
                                value = { isNil(mention) ? '' : mention }
                                type = 'text'
                                data-hj-whitelist
                                onBlur = { onInputBlur }
                                onChange = { onInputFieldChange }/>
                        </div>
                    </div>
                </div>
                <div className = 'gac-form-row'>
                    <div className = 'gac-form-field'>
                        <span className = 'gac-form-label'>Avoid</span>
                        <div className = 'gac-form-input'>
                            <input
                                id = 'avoid'
                                value = { isNil(avoid) ? '' : avoid }
                                type = 'text'
                                data-hj-whitelist
                                onBlur = { onInputBlur }
                                onChange = { onInputFieldChange }/>
                        </div>
                    </div>
                </div>
                <div className = 'gac-form-row'>
                    <div className = 'gac-form-field'>
                        <span className = 'gac-form-label'>Similar content</span>
                        <div className = 'gac-form-input'>
                            <input
                                id = 'similar_content'
                                value = { isNil(similar_content) ? '' : similar_content }
                                type = 'text'
                                data-hj-whitelist
                                onBlur = { onInputBlur }
                                onChange = { onInputFieldChange }/>
                        </div>
                    </div>
                </div>
            </> ;
        }

        return <>
            <div className="gac-brief-field">
                <div className="gac-brief-label">Target audience:</div>
                <div className="gac-brief-data">{targetAudiences}</div>
            </div>
            <div className="gac-brief-field">
                <div className="gac-brief-label">Content:</div>
                <div className="gac-brief-data">{ contentData }</div>
            </div>
            <div className="gac-brief-field">
                <div className="gac-brief-label">Mention:</div>
                <div className="gac-brief-data">{ mention }</div>
            </div>
            <div className="gac-brief-field">
                <div className="gac-brief-label">Avoid:</div>
                <div className="gac-brief-data">{ avoid }</div>
            </div>
            <div className="gac-brief-field">
                <div className="gac-brief-label">Similar content:</div>
                <div className="gac-brief-data">{ similar_content }</div>
            </div>
        </> ;
    };

    /* Design */
    const getFormatDescriptionField = () => {
        if ( isNil(format_description) ) return null;

        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Description of your company/niche and your top values</span>
                    <div id = 'format_description' className = { `gac-form-textarea ${invalidFields.includes('format_description') ? 'gac-invalid' : ''}` }>
                        <textarea id = 'format_description-textarea' value = { format_description } readOnly />
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Format description:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{__html: format_description}}/>
            </div>
        </div> ;
    };
    const getFormatTargetAudienceField = () => {
        if ( isNil(format_target_audience) ) return null;
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Target audience of your product/service: their psychology, age group, customer journey</span>
                    <div id = 'format_target_audience' className = { `gac-form-textarea ${invalidFields.includes('format_target_audience') ? 'gac-invalid' : ''}` }>
                        <textarea id = 'format_target_audience-textarea' value = { format_target_audience } readOnly />
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Format target audience:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{__html: format_target_audience}}/>
            </div>
        </div> ;
    };
    const getFormatReasonField = () => {
        if ( isNil(format_reason) ) return null;
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Why do you need the new logo design?</span>
                    <div id = 'format_reason' className = { `gac-form-textarea ${invalidFields.includes('format_reason') ? 'gac-invalid' : ''}` }>
                        <textarea id = 'format_reason-textarea' value = { format_reason } readOnly />
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Format reason:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{__html: format_reason}}/>
            </div>
        </div> ;
    };
    const getDimensionsField = () => {
        if ( isNil(dimensions) || isEmpty(dimensions) ) return null;
        let data = dimensions.map(o => {
            const isEmptyH = isNil(o.horizontal) || o.horizontal === 0;
            const isEmptyV = isNil(o.vertical) || o.vertical === 0;
            const isEmptyCode = isNil(o.unit_code) || isEmpty(o.unit_code);
            const isEmptyAll = isEmptyH && isEmptyV;
            return `${o.dimension_name}${isEmptyAll ? '' : ':'} ${ isEmptyH ? '' : o.horizontal } ${ !isEmptyH && !isEmptyV ? 'x' : '' } ${ isEmptyV ? '' : o.vertical} ${ isEmptyCode || (isEmptyH && isEmptyV) ? '' : o.unit_code}`;
        });

        return(
            <div className="gac-brief-field">
                <span className = 'gac-brief-label'>Dimensions:</span>
                <div className="gac-brief-data">
                    <div className="gac-brief-content" dangerouslySetInnerHTML = {{__html: data.join('<br/>')}}/>
                </div>
            </div>
        );
    };
    const getIncludedTextField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Include text, word for word</span>
                    <div id = 'included_text' className = 'gac-form-textarea'>
                        <textarea id = 'included_text-textarea' value = { included_text } readOnly />
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Include text, word for word:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{__html: included_text}}/>
            </div>
        </div> ;
    };
    const getDesignReferencesField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Design references</span>
                    <div id = 'design_references' className = 'gac-form-textarea'>
                        <textarea id = 'design_references-textarea' value = { design_references } readOnly />
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Design references:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{__html: design_references}}/>
            </div>
        </div> ;
    };
    const getBrandProfilesField = () => {
        const profiles = isNil(brand_profiles) ? [] : brand_profiles;

        if ( (isEditing && ( isNil(brandProfiles) || isEmpty(brandProfiles) )) || (!isEditing && isEmpty(profiles))) return null;

        const data = brandProfiles
            .filter(({ account_uid: uid }) => uid === account_uid )
            .filter(o => o.active)
            .map(({ id, name, colors }) => {
                return(
                    <li
                        key = { id }
                        data-value = { id }
                        className = { includes(id, profiles) ? 'gac-active' : '' }
                        onClick = { onBrandProfileRadioClick }>
                        <i style = {{ backgroundColor: isNil(colors) || isEmpty(colors) ? '#59C69F' : colors[0], border: isNil(colors) || isEmpty(colors) ? null : colors[0] === '#ffffff' ? '2px solid #D9D9D9' : null }}/>
                        <p title = { name }>{ name }</p>
                        <span data-id = { id } className="gac-edit" onClick = { onBrandProfileEdit }/>
                    </li>
                );
            });
        const profilesArr = brandProfiles.filter(o => includes(o.id, profiles));
        if ( (isNil(profilesArr) || isEmpty(profilesArr)) && !isEditing ) return null;

        const profilesData = profilesArr.map(o => {
            return <div key = { o.id } className='gac-brand-profile-item'>
                { isNil(o.colors) || isEmpty(o.colors) ? null : <span className="gac-profile-color" style={{ backgroundColor:  o.colors[0], border: o.colors[0] === '#ffffff' ? '2px solid #D9D9D9' : null }}/> }
                <span data-id = { o.id } onClick = { onBrandProfileEdit } className="gac-profile-name">{ o.name }</span>
            </div>;
        });

        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Brand profile</span>
                </div>
                <div id = 'brand_profiles' className="gac-brand-profiles">
                    <ul>
                        { data }
                        <li data-value = 'create-new-profile' onClick = { onBrandProfileRadioClick }>
                            <i><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="11" stroke="#59C69F" strokeWidth="2"/><path d="M11 7a1 1 0 112 0v10a1 1 0 11-2 0V7z" fill="#59C69F"/><path d="M7 13a1 1 0 110-2h10a1 1 0 110 2H7z" fill="#59C69F"/></svg></i>
                            <p>Create new profile</p>
                        </li>
                    </ul>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            <span className = 'gac-brief-label'>Brand profile:</span>
            <div className="gac-brief-data">
                <div className="gac-brand-profile">
                    { profilesData }
                </div>
            </div>
        </div> ;
    };
    const getFileTypesField = () => {
        let data = fileTypesObj['design'].filter(o => o.code !== 'fig').filter(o => o.code !== 'gif');
        const fig = fileTypesObj['design'].filter(o => o.code === 'fig')[0];
        const gif = fileTypesObj['design'].filter(o => o.code === 'gif')[0];
        const index = data.findIndex(o => o.code === 'ai');
        if ( !isNil(fig) && index >= 0 ) {
            data.splice(index,0, fig);
        }
        if ( !isNil(gif) && index >= 0 ) {
            data.splice(index,0, gif);
        }

        if ( job_type === 'motion' ) {
            data = data.filter(o => includes(o.code, 'gif/mp4/mov/ae/lottie'));
        } else {
            if ( `${format}` === '114' ) {
                data = data.filter(o => includes(o.code, 'gif/mp4/mov/webm/ae/lottie'));
            } else {
                data = data.filter(o => !includes(o.code, 'gif/mp4/mov/webm/ae/lottie'));
            }
            if ( `${format}` === '101' ) {
                data = data.filter(o => includes(o.code, 'jpg/png/pdf/fig/indd/ppt/key/gslides'));
            } else {
                data = data.filter(o => !includes(o.code, 'ppt/key/gslides'));
            }
        }
        if ( !isNil(used_services) && includes('canva', used_services) ) {
            data = data.filter(o => !includes(o.code, 'svg/esp/fig/ai/psd/indd/lottie'));
        }

        data = data.map(o => {
            return <div
                key = { o.code }
                data-value = { o.code }
                data-prop='file_types'
                style = {{ borderColor: invalidFields.includes('file_types') ? '#dc5d5b' : null, marginLeft: o.code === 'fig' && !isMobile ? 40 : null }}
                className = { `gac-file-types__item ${includes(o.code,file_types) ? 'gac-file-types__active' : ''}` }
                onClick = { onFileTypesChange }>
                { o.name }
            </div> ;
        });

        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>File types</span>
                    <div id='file_types' className="gac-file-types">{ data }</div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            <span className = 'gac-brief-label'>File types:</span>
            <div className="gac-brief-data">{ file_types.join(', ') }</div>
        </div> ;
    };
    const getImagesField = () => {
        const imageOptionFiles = isNil(image_option_files) ? [] : image_option_files;
        const imageFilesData = imageOptionFiles.map(o => {
            return(
                <li key = { o.file_id }>
                    <img data-url = { o.url } src = { o.url_resize } onClick = { onImageFilesClick } alt=''/>
                    { isEditing && <span data-id = { o.file_id } onClick = { onImageFilesRemove }>
                        <svg width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5" cy="5" r="5" fill="#fff"/><path fillRule="evenodd" clipRule="evenodd" d="M7.121 2.879a.5.5 0 00-.707 0L5 4.293 3.586 2.879a.5.5 0 00-.707.707L4.293 5 2.879 6.414a.5.5 0 00.707.707L5 5.707l1.414 1.414a.5.5 0 10.707-.707L5.707 5l1.414-1.414a.5.5 0 000-.707z" fill="#59C69F"/></svg>
                    </span> }
                </li>
            );
        });

        if ( isEditing ) {
            return <div className = 'gac-form-row' style={{ marginBottom: 16 }}>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Images</span>
                    <div className="gac-design-images">
                        <ul id='image_option' className = { invalidFields.includes('image_option') ? 'gac-invalid' : '' }>
                            <li data-value = 'browse-images' className = { image_option === 'browse-images' ? 'gac-active' : '' } onClick = { onDesignImagesRadioClick }>
                                <i><svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.4 6.4H0v3.2h6.4V16h3.2V9.6H16V6.4H9.6V0H6.4v6.4z" fill="#59C69F"/></svg></i>
                                <p>Browse images</p>
                            </li>
                            <li data-value = 'find-images-for-me' className = { image_option === 'find-images-for-me' ? 'gac-active' : '' } onClick = { onDesignImagesRadioClick }>
                                <i><svg xmlns="http://www.w3.org/2000/svg" width="25.6" height="22"><path fill="#59C69F" d="M21.2 9.7c0 2.4-.9 4.6-2.6 6.3-3.1 3-7.8 3.5-11.4 1.1l-.3-.2-4.8 4.8c-.2.2-.4.3-.7.3s-.5-.1-.7-.3l-.4-.4c-.4-.4-.4-1 0-1.4L5 15l-.2-.3C2.4 11.1 2.9 6.3 5.9 3.3 7.6 1.6 9.9.7 12.3.7h.8l-.4.4c-.6.6-.9 1.3-.9 2.1-1.5.1-2.9.7-4 1.8-2.5 2.5-2.5 6.6 0 9.1s6.6 2.5 9.1 0c1.1-1.1 1.7-2.5 1.8-3.9.8 0 1.7-.2 2.3-.9l.3-.3c-.1.3-.1.5-.1.7z"/><path fill="#59C69F" d="M25.3.7l-.4-.4c-.4-.4-1-.4-1.4 0l-3.2 3.2-.2.2-.3.3-1.1 1.1-1.4-1.4-1.5-1.5c-.4-.4-1-.4-1.4 0l-.4.4c-.2.2-.3.6-.3.9 0 .2.1.4.3.5l4 4 .1.1c.1.1.2.1.3.2.3.1.7 0 1-.3L21 6.3l.4-.4 3.9-3.7c.4-.4.4-1.1 0-1.5zm-.4 1.1z"/></svg></i>
                                <p>Find images for me</p>
                            </li>
                            <li data-value = 'use-attached' className = { image_option === 'use-attached' ? 'gac-active' : '' } onClick={ onDesignImagesRadioClick }>
                                <i><svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.9307 20.0281C-0.643565 17.3989 -0.643565 13.1438 1.9307 10.5146L10.8221 1.43351C12.6935 -0.477838 15.725 -0.477838 17.5964 1.43351C19.4679 3.34487 19.4679 6.44108 17.5964 8.35243L10.3987 15.7038C9.23008 16.8973 7.33326 16.8973 6.16468 15.7038C4.9961 14.5103 4.9961 12.573 6.16468 11.3795L11.6689 5.75784C12.1365 5.28019 12.8948 5.28019 13.3625 5.75784C13.8301 6.23549 13.8301 7.00992 13.3625 7.48757L7.78206 13.187C7.31632 13.6627 8.16312 14.5276 8.62886 14.0519L15.9028 6.6227C16.8343 5.67135 16.8343 4.11459 15.9028 3.16324C14.9714 2.21189 13.4471 2.21189 12.5157 3.16324L3.62429 12.2443C1.98997 13.9135 1.98997 16.6292 3.62429 18.2984C5.25861 19.9676 7.91755 19.9676 9.55187 18.2984L16.7496 10.947C17.2173 10.4694 17.9756 10.4694 18.4432 10.947C18.9109 11.4247 18.9109 12.1991 18.4432 12.6768L11.2455 20.0281C8.6712 22.6573 4.50496 22.6573 1.9307 20.0281Z" fill="#59C69F"/></svg></i>
                                <p>Use attached images</p>
                            </li>
                            <li data-value = 'do-not-use' className = { image_option === 'do-not-use' ? 'gac-active' : '' } onClick={ onDesignImagesRadioClick }>
                                <i><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.42442 7.86126L0.273438 13.0122L2.84892 15.5877L7.99991 10.4367L13.1509 15.5878L15.7264 13.0123L10.5754 7.86126L15.7263 2.7103L13.1509 0.134819L7.99991 5.28577L2.84899 0.134858L0.27351 2.71034L5.42442 7.86126Z" fill="#59C69F"/></svg></i>
                                <p>Do not use images</p>
                            </li>
                        </ul>
                    </div>
                    { !isEmpty(imageFilesData) && <ul className="gac-design-files">
                        { imageFilesData }
                        <li className='gac-add-new-file' onClick={ () => { setModal('images') } }>
                            <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10.5 0h-1v9.5H0v1h9.5V20h1v-9.5H20v-1h-9.5V0z" fill="#59C69F"/></svg>
                        </li>
                    </ul> }
                </div>
            </div> ;
        }

        if ( isNil(image_option) ) return null;

        return <div className="gac-brief-field" style={{ marginBottom: 16 }}>
            <span className = 'gac-brief-label'>Images:</span>
            <div className="gac-brief-data">
                { image_option === 'browse-images'
                    ? !isEmpty(imageFilesData) && <ul style={{ marginTop: isMobile ? 10 : 0, marginBottom: isMobile ? 10 : 0 }} className="gac-design-files">{ imageFilesData }</ul>
                    : imagesLabels[image_option] }
            </div>
        </div> ;
    };
    const getImageNotesField = () => {
        const label = image_option === 'browse-images' ? 'Notes about selected images' : 'What are you looking for?';

        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>{ label }</span>
                    <div id = 'image_notes' className = 'gac-form-textarea'>
                        <textarea id = 'image_notes-textarea' value = { image_notes } readOnly />
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>{ label }:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{ __html: image_notes }}/>
            </div>
        </div> ;
    };
    const getWorkflowField = () => {
        if ( isEditing ) {
            const config = [
                { value: 'moodboard', label: 'Moodboard', getSvg: () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" fill="none"><path fill="#61A89C" d="M0 2.361A2.361 2.361 0 0 1 2.361 0H21.25a2.36 2.36 0 0 1 2.361 2.361V18.89a2.361 2.361 0 0 1-2.361 2.361H2.361A2.361 2.361 0 0 1 0 18.889V2.36Zm7.083 0H2.361V18.89h4.722V2.36Zm2.361 0V18.89h4.723V2.36H9.444Zm7.084 0V18.89h4.722V2.36h-4.722Z"/></svg> },
                { value: 'wireframes', label: 'Wireframes', getSvg: () => <svg xmlns="http://www.w3.org/2000/svg" width="42" height="27" fill="none"><path fill="#61A89C" d="M0 0v10.5h10.5V0H0Zm9.188 9.188H1.311V1.311h7.875v7.875Z"/><path fill="#61A89C" d="M0 0v10.5h10.5V0H0Zm9.188 9.188H1.311V1.311h7.875v7.875ZM15.75 0v10.5h10.5V0h-10.5Zm9.188 9.188h-7.875V1.311h7.875v7.875Z"/><path fill="#61A89C" d="M15.75 0v10.5h10.5V0h-10.5Zm9.188 9.188h-7.875V1.311h7.875v7.875ZM31.5 0v10.5H42V0H31.5Zm9.188 9.188h-7.876V1.311h7.876v7.875Z"/><path fill="#61A89C" d="M31.5 0v10.5H42V0H31.5Zm9.188 9.188h-7.876V1.311h7.876v7.875ZM0 15.75v10.5h10.5v-10.5H0Zm9.188 9.188H1.311v-7.875h7.875v7.875Z"/><path fill="#61A89C" d="M0 15.75v10.5h10.5v-10.5H0Zm9.188 9.188H1.311v-7.875h7.875v7.875ZM15.75 15.75v10.5h10.5v-10.5h-10.5Zm9.188 9.188h-7.875v-7.875h7.875v7.875Z"/><path fill="#61A89C" d="M15.75 15.75v10.5h10.5v-10.5h-10.5Zm9.188 9.188h-7.875v-7.875h7.875v7.875ZM31.5 15.75v10.5H42v-10.5H31.5Zm9.188 9.188h-7.876v-7.875h7.876v7.875Z"/><path fill="#61A89C" d="M31.5 15.75v10.5H42v-10.5H31.5Zm9.188 9.188h-7.876v-7.875h7.876v7.875Z"/></svg> },
            ];
            const data = config.map(o => {
                return <li key = { o.value } data-value = { o.value } className = { !isNil(workflow) && !isEmpty(workflow) && includes(o.value, workflow) ? 'gac-active' : '' } onClick = { onWorkflowRadioClick }>
                    <i>{ o.getSvg() }</i>
                    <p>{ o.label }</p>
                </li> ;
            });

            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Workflow</span>
                    <div className="gac-design-workflow">
                        <ul>{ data }</ul>
                    </div>
                </div>
            </div> ;
        }

        if ( isNil(workflow) || isEmpty(workflow) ) return null;

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Workflow:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{__html: workflow.map(el => upperCaseFirstLetter(el)).join(', ')}}/>
            </div>
        </div> ;
    };
    const getSitemapField = () => {
        if ( isEmpty(format) || !includes(format, '106/107') ) return null;

        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Sitemap</span>
                    <div className = 'gac-form-input'>
                        <input
                            id = 'sitemap'
                            data-hj-whitelist
                            value = { isNil(sitemap) ? '' : sitemap }
                            type = 'text'
                            onBlur = { onInputBlur }
                            onChange = { onInputFieldChange }/>
                    </div>
                </div>
            </div> ;
        }

        if ( isNil(sitemap) || isEmpty(sitemap) ) return null;

        return <div className="gac-brief-field">
            <div className="gac-brief-label">Sitemap:</div>
            <div className="gac-brief-data">{ sitemap }</div>
        </div> ;
    };
    // const getSmartMatchField = () => {
    //     if ( isEditing ) {
    //         return <div className='gac-form-row gac-row-match'>
    //             <div className='gac-form-field'>
    //                 <span className='gac-form-label'>Writer match</span>
    //                 <div className='gac-form-field-content'>
    //                     <div className='gac-form-radio'>
    //                         <div onClick = { onRadioFieldChange } data-value='yes' data-prop='smart_match' className={`gac-radio-item ${smart_match === 'yes' ? 'gac-item-active' : ''}`}>Smart match</div>
    //                         <div onClick = { onRadioFieldChange } data-value='no' data-prop='smart_match' className={`gac-radio-item ${smart_match === 'no' ? 'gac-item-active' : ''}`}>Review pitches</div>
    //                     </div>
    //                     <Hint text='Smart Match is a fast, reliable method to get the right writer for your project. Our editors review hundreds of projects each week and know which writer is the best match for your brief.'/>
    //                 </div>
    //             </div>
    //         </div> ;
    //     }
    //
    //     return <div className="gac-brief-field">
    //         <div className="gac-brief-label">Match:</div>
    //         <div className="gac-brief-data">
    //             { smart_match === 'yes' ? 'Smart match' : 'Review pitches' }
    //         </div>
    //     </div> ;
    // };
    const getUsedServicesField = () => {
        if ( isEditing ) {
            const services = isNil(used_services) ? [] : used_services;
            const config = [
                { value: 'canva', label: 'Canva' },
            ];
            const html = config.map(({ value, label }) => {
                return <div key = { value } data-value = { value } onClick = { onUsedServicesChange } className = { `gac-radio-item ${ includes(value, services) ? 'gac-item-active' : ''}` }>{ label }</div>;
            });

            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Used services</span>
                    <div className='gac-form-field-content'>
                        <div className='gac-form-radio' style={{ width: `100%` }}>
                            { html }
                        </div>
                    </div>
                </div>
            </div> ;
        }

        if ( isNil(used_services) || isEmpty(used_services) ) return null;

        return <div className="gac-brief-field">
            <span className = 'gac-brief-label'>Used services:</span>
            <div className="gac-brief-data">{ used_services.map(el => upperCaseFirstLetter(el)).join(', ') }</div>
        </div> ;
    };
    const getReviewFrequencyField = () => {
        const config = [
            { value: 'every_business_day', title: 'Every business day' },
            { value: 'upon_project_completion', title: 'Upon completion of the project' },
        ];
        const title = getTitle(config, review_frequency);

        if ( isEditing ) {
            return(
                <div className = 'gac-form-row'>
                    <div className = 'gac-form-field gac-field-1-2'>
                        <span className = 'gac-form-label'>Review frequency</span>
                        <Select
                            isMultiple = { false }
                            items = { config }
                            value = { review_frequency ? review_frequency : '' }
                            title = { title }
                            parameter = 'review_frequency'
                            onChange = { onFormFieldChange }/>
                    </div>
                </div>
            );
        }

        return <div className="gac-brief-field">
            <div className="gac-brief-label">Review frequency:</div>
            <div className="gac-brief-data">{ title }</div>
        </div> ;
    };

    /* Motion */
    const getFormatOptionsField = () => {
        if ( isNil(format_options) || isEmpty(format_options) ) return null;
        const formatOptionsData = format_options;
        const currencyCode = getCurrencyCode(currency);
        const optionsArr = formats[job_type].filter(o => o.formats.some(a => `${a.id}` === `${format}`))[0].formats.filter(a => `${a.id}` === `${format}`)[0].options;
        const data = uniq(formatOptionsData.map(o => o.id)).map(id => {
            const optionObj = formatOptionsData.filter(a => `${a.id}` === `${id}`);
            const multiplier = optionObj.length;
            const option = optionsArr.filter(b => `${b.id}` === `${id}`)[0];
            const { title, custom } = option;
            const { amount } = optionObj[0];
            return `${title}, ${currency}${ !!custom ? toLocaleString(Number(amount)) : toLocaleString(option[`price_${currencyCode}`])} ${ multiplier > 1 ? `x ${multiplier}` : '' }`;
        });

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Options:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{ __html: data.join('<br/>') }}/>
            </div>
        </div> ;
    };
    const getAttachFileField = () => {
        const logoFiles = isNil(logo_file) ? [] : logo_file;
        const logoFilesData = logoFiles.map(({ file_id, filename, size, url }) => {
            return <a href = { url } target='_blank' rel = 'noopener noreferrer' key = { file_id } className = 'gac-project-file'>
                <i className = { `gac-project-file-type ${ filename.split('.').slice(-1)[0] }` }/>
                <span title = { filename } className = 'gac-project-file-name'>{ filename }</span>
                <div className = 'gac-align-right'>
                    <span className = 'gac-project-file-size'>{ bytesToSize(size) }</span>
                </div>
            </a>;
        });

        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Attach file (.eps or .ai file preferred)</span>
                    { getDropzoneMotion() }
                </div>
            </div> ;
        }

        return <div className="gac-brief-field" style={{ marginBottom: 16 }}>
            <span className = 'gac-brief-label'>Attached file:</span>
            <div className="gac-brief-data gac-with-files">
                <div className="gac-project-files" style={{ paddingTop: 0 }}>{ logoFilesData }</div>
            </div>
        </div> ;
    };
    const getBrandDescriptionField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Text to be included in the motion graphic, word for word (leave blank if none)</span>
                    <div id = 'brand_description' className = { `gac-form-textarea ${invalidFields.includes('brand_description') ? 'gac-invalid' : ''}` }>
                        <textarea id = 'brand_description-textarea' value = { brand_description } readOnly />
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Included text:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{__html: brand_description}}/>
            </div>
        </div> ;
    };
    const getIdeasField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Your ideas/storyboard/script for the animation</span>
                    <div id = 'ideas' className = { `gac-form-textarea ${invalidFields.includes('ideas') ? 'gac-invalid' : ''}` }>
                        <textarea id = 'ideas-textarea' value = { ideas } readOnly />
                    </div>
                </div>
                { getMotionIdeasDropzoneHtml() }
            </div> ;
        }

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Ideas:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{__html: ideas}}/>
                { getFilesHtml(idea_files) }
            </div>
        </div> ;
    };
    const getExamplesField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Links to examples of animations you like</span>
                    <div id = 'examples' className = { `gac-form-textarea ${invalidFields.includes('examples') ? 'gac-invalid' : ''}` }>
                        <textarea id = 'examples-textarea' value = { examples } readOnly />
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Examples:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{__html: examples}}/>
            </div>
        </div> ;
    };
    const getMotionAnimationLengthField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Animation duration</span>
                    <div className = 'gac-form-input'>
                        <input
                            className = { invalidFields.includes('length') ? 'gac-invalid' : '' }
                            id = 'length'
                            data-hj-whitelist
                            value = { animationLength }
                            type = 'text'
                            onBlur = { onInputBlur }
                            onChange = { onInputFieldChange }/>
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            <span className = 'gac-brief-label'>Animation duration:</span>
            <div className="gac-brief-data">{ animationLength }</div>
        </div> ;
    };

    /* Webaudit */
    const getWebsiteField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Link to the website</span>
                    <div className = 'gac-form-input'>
                        <input
                            id = 'website'
                            className = { invalidFields.includes('website') ? 'gac-invalid' : '' }
                            value = { website }
                            type = 'text'
                            data-hj-whitelist
                            onBlur = { onInputBlur }
                            onChange = { onInputFieldChange }/>
                    </div>
                </div>
            </div> ;
        }

        return <>
            <div className="gac-brief-field">
                <div className="gac-brief-label">Link to the website:</div>
                <div className="gac-brief-data">{ website }</div>
            </div>
        </>;
    };
    const getCmsField = () => {
        if ( isEmpty(cms) || isNil(cms) ) return null;
        const items = cmsData
            .filter(o => o.active)
            .sort((a, b) => a.sort - b.sort)
            .map(o => ({ value: `${o.id}`, title: o.name }));
        const cmsName = cmsData.filter(o => `${o.id}` === `${cms}`)[0].name;

        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Website content management system</span>
                    <Select
                        items = { items }
                        value = { `${cms}` }
                        title = { cms ? cmsName : '' }
                        parameter = 'cms'
                        invalid = { invalidFields.includes('cms') }
                        onChange = { onFormFieldChange }/>
                </div>
            </div> ;
        }

        return <>
            <div className="gac-brief-field">
                <div className="gac-brief-label">CMS:</div>
                <div className="gac-brief-data">{ cmsName }</div>
            </div>
        </>;
    };
    const getGoogleServicesField = () => {
        const items = [
            { value: 'yes', title: 'Yes' },
            { value: 'no', title: 'No' },
            { value: 'unknown', title: `I don't know` },
        ];

        if ( isEditing ) {
            return <div className = 'gac-form-row gac-row-multiple'>
                <div className = 'gac-form-field gac-field-1-2'>
                    <span className = 'gac-form-label'>Do you use Google Analytics?</span>
                    <Select
                        items = { items }
                        value = { google_analytics }
                        title = { google_analytics ? items.filter(o => `${o.value}` === google_analytics)[0].title : '' }
                        parameter = 'google_analytics'
                        invalid = { invalidFields.includes('google_analytics') }
                        onChange = { onFormFieldChange }/>
                </div>
                <div className = 'gac-form-field gac-field-1-2'>
                    <span className = 'gac-form-label'>Do you use Google Search Console?</span>
                    <Select
                        items = { items }
                        value = { google_search_console }
                        title = { google_search_console ? items.filter(o => `${o.value}` === google_search_console)[0].title : '' }
                        parameter = 'google_search_console'
                        invalid = { invalidFields.includes('google_search_console') }
                        onChange = { onFormFieldChange }/>
                </div>
            </div> ;
        }

        return <>
            <div className="gac-brief-field">
                <div className="gac-brief-label">Do you use Google Analytics?</div>
                <div className="gac-brief-data">{ items.filter(o => `${o.value}` === google_analytics)[0].title }</div>
            </div>
            <div className="gac-brief-field">
                <div className="gac-brief-label">Do you use Google Search Console?</div>
                <div className="gac-brief-data">{ items.filter(o => `${o.value}` === google_search_console)[0].title }</div>
            </div>
        </>;
    };
    const getLinkToResourcesField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Link to the company's blog/resources page</span>
                    <div className = 'gac-form-input'>
                        <input
                            id = { job_type === 'seoaudit' ? 'resources' : 'link_to_resources' }
                            className = { invalidFields.includes(job_type === 'seoaudit' ? 'resources' : 'link_to_resources') ? 'gac-invalid' : '' }
                            value = { job_type === 'seoaudit' ? resources : link_to_resources }
                            type = 'text'
                            data-hj-whitelist
                            onBlur = { onInputBlur }
                            onChange = { onInputFieldChange }/>
                    </div>
                </div>
            </div>;
        }

        return <div className="gac-brief-field">
            <div className="gac-brief-label">Resources:</div>
            <div className="gac-brief-data">{ job_type === 'seoaudit' ? resources : link_to_resources }</div>
        </div> ;
    };
    const getProductsField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>List up to 5 keywords (products or services your company sells)</span>
                    <div id = 'products' className = { `gac-form-textarea ${invalidFields.includes('products') ? 'gac-invalid' : ''}` }>
                        <textarea id = 'products-textarea' value = { products } readOnly />
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Products:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{__html: products}}/>
            </div>
        </div> ;
    };
    const getTargetAudienceTextarea = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Describe the target audience of your website</span>
                    <div id = 'target_audience' className = { `gac-form-textarea ${invalidFields.includes('target_audience') ? 'gac-invalid' : ''}` }>
                        <textarea id = 'target_audience-textarea' value = { target_audience } readOnly />
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Target audience:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{__html: target_audience}}/>
            </div>
        </div> ;
    };
    const getGoalsField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>{ job_type === 'seoaudit' ? 'Main goals you want to achieve with this audit' : 'Explain what goals you want to achieve with your website' }</span>
                    <div id = 'goals' className = { `gac-form-textarea ${invalidFields.includes('goals') ? 'gac-invalid' : ''}` }>
                        <textarea id = 'goals-textarea' value = { goals } readOnly />
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Goals:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{ __html: goals }}/>
            </div>
        </div> ;
    };
    const getNotesField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Additional information</span>
                    <div id = 'notes' className = 'gac-form-textarea'>
                        <textarea id = 'notes-textarea' value = { notes } readOnly />
                    </div>
                </div>
            </div> ;
        }

        if ( isEmpty(notes) || isNil(notes) ) return null;

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Additional information:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{ __html: notes }}/>
            </div>
        </div> ;
    };

    /* SEO audit */
    const getSeoAuditNotesField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Have you performed a SEO audit before? If so, what were the main insights?</span>
                    <div id = 'seo_audit_notes' className = 'gac-form-textarea'>
                        <textarea id = 'seo_audit_notes-textarea' value = { seo_audit_notes } readOnly />
                    </div>
                </div>
            </div> ;
        }

        if ( isEmpty(seo_audit_notes) || isNil(seo_audit_notes) ) return null;

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Notes:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{ __html: seo_audit_notes }}/>
            </div>
        </div> ;
    };
    const getFocusAreasField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>
                        { seo_type === 'technical'
                            ? 'Specific areas of your website you want us to focus on'
                            : 'Which areas of your marketing strategy do you feel need the most improvement?' }
                    </span>
                    <div id = 'focus_areas' className = { `gac-form-textarea ${invalidFields.includes('focus_areas') ? 'gac-invalid' : ''}` }>
                        <textarea id = 'focus_areas-textarea' value = { focus_areas } readOnly />
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Focus areas:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{ __html: focus_areas }}/>
            </div>
        </div> ;
    };
    const getKeywordsGoogleField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Name 10 keywords you want to rank in the top 10 on Google with</span>
                    <div id = 'keywords_google' className = { `gac-form-textarea ${invalidFields.includes('keywords_google') ? 'gac-invalid' : ''}` }>
                        <textarea id = 'keywords_google-textarea' value = { keywords_google } readOnly />
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Keywords google:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{ __html: keywords_google }}/>
            </div>
        </div> ;
    };
    const getKeywordsField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Are there any specific keywords or pages you're struggling to rank for?</span>
                    <div id = 'keywords' className = { `gac-form-textarea ${invalidFields.includes('keywords') ? 'gac-invalid' : ''}` }>
                        <textarea id = 'keywords-textarea' value = { keywords } readOnly />
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Keywords:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{ __html: keywords }}/>
            </div>
        </div> ;
    };
    const getMarketingChannelsField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>What other marketing channels are you currently using?</span>
                    <div id = 'marketing_channels' className = { `gac-form-textarea ${invalidFields.includes('marketing_channels') ? 'gac-invalid' : ''}` }>
                        <textarea id = 'marketing_channels-textarea' value = { marketing_channels } readOnly />
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Marketing channels:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{ __html: marketing_channels }}/>
            </div>
        </div> ;
    };
    const getMonitoringToolsField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>What tools do you use to monitor your site's performance?</span>
                    <div id = 'monitoring_tools' className = { `gac-form-textarea ${invalidFields.includes('monitoring_tools') ? 'gac-invalid' : ''}` }>
                        <textarea id = 'monitoring_tools-textarea' value = { monitoring_tools } readOnly />
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Monitoring tools:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{ __html: monitoring_tools }}/>
            </div>
        </div> ;
    };
    const getSeoStrategiesField = () => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Are there any competitor SEO strategies that you find effective?</span>
                    <div id = 'seo_strategies' className = { `gac-form-textarea ${invalidFields.includes('seo_strategies') ? 'gac-invalid' : ''}` }>
                        <textarea id = 'seo_strategies-textarea' value = { seo_strategies } readOnly />
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>Seo strategies:</span> }
            <div className="gac-brief-data">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{ __html: seo_strategies }}/>
            </div>
        </div> ;
    };
    const getSeoAuditedField = () => {
        if ( isNil(seo_audited) || isEmpty(seo_audited) ) return null;
        const items = [
            { value: 'yes', title: 'Yes' },
            { value: 'no', title: 'No' },
            { value: 'unknown', title: `I don't know` },
        ];

        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Have you ever had an SEO audit performed on your website before?</span>
                    <Select
                        items = { items }
                        value = { seo_audited }
                        title = { seo_audited ? items.filter(o => `${o.value}` === seo_audited)[0].title : '' }
                        parameter = 'seo_audited'
                        invalid = { invalidFields.includes('seo_audited') }
                        onChange = { onFormFieldChange }/>
                </div>
            </div> ;
        }

        return <>
            <div className="gac-brief-field">
                <div className="gac-brief-label">SEO audit:</div>
                <div className="gac-brief-data">{ items.filter(o => `${o.value}` === seo_audited)[0].title }</div>
            </div>
        </>;
    };
    const getLinkAnalyzedField = () => {
        const items = [
            { value: 'yes', title: 'Yes' },
            { value: 'no', title: 'No' },
            { value: 'unknown', title: `I don't know` },
        ];

        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Have you ever had a disavow link analysis performed on your website before?</span>
                    <Select
                        items = { items }
                        value = { link_analized }
                        title = { link_analized ? items.filter(o => `${o.value}` === link_analized)[0].title : '' }
                        parameter = 'link_analized'
                        invalid = { invalidFields.includes('link_analized') }
                        onChange = { onFormFieldChange }/>
                </div>
            </div> ;
        }

        return <>
            <div className="gac-brief-field">
                <div className="gac-brief-label">Link analyzed:</div>
                <div className="gac-brief-data">{ items.filter(o => `${o.value}` === link_analized)[0].title }</div>
            </div>
        </>;
    };
    const getGoogleAccessField = () => {
        const items = [
            { value: 'done', title: 'Done' },
            { value: 'help', title: 'Need help' },
        ];

        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>Please send access to Google Analytics and Google Search Console to audits@epiic.com</span>
                    <Select
                        items = { items }
                        value = { google_access }
                        title = { google_access ? items.filter(o => `${o.value}` === google_access)[0].title : '' }
                        parameter = 'google_access'
                        invalid = { invalidFields.includes('google_access') }
                        onChange = { onFormFieldChange }/>
                </div>
            </div> ;
        }

        return <>
            <div className="gac-brief-field">
                <div className="gac-brief-label">Google access</div>
                <div className="gac-brief-data">{ items.filter(o => `${o.value}` === google_access)[0].title }</div>
            </div>
        </>;
    };

    const getSeoBriefTypeField = () => {
        if ( isEditing ) {
            return <div className='gac-form-row gac-row-turnaround'>
                <div className='gac-form-field'>
                    {/*<span className='gac-form-label'>SEO brief type</span>*/}
                    <div className='gac-form-field-content'>
                        <div className={`gac-form-radio `}>
                            <div
                                onClick={onSeoBriefTypeChange}
                                data-value='page'
                                data-prop='seobrief_type'
                                style={{width: 196}}
                                className={`gac-radio-item ${seobrief_type === 'page' ? 'gac-item-active' : ''}`}>
                                Service or product page
                            </div>
                            <div
                                onClick={onSeoBriefTypeChange}
                                data-value='post'
                                data-prop='seobrief_type'
                                className={`gac-radio-item ${seobrief_type === 'post' ? 'gac-item-active' : ''}`}>
                                Blog post
                            </div>
                        </div>
                    </div>
                </div>
            </div>;
        }
    };

    const getTargetAudienceContentFields = () => {
        const targetAudiences = getTitle(target_audiences, target_audience);
        const contentData = getTitle(content_types, content);

        if ( isEditing ) {
            return <>
                <div className = 'gac-form-row gac-row-multiple'>
                    <div className='gac-form-field gac-field-1-2'>
                        <span className='gac-form-label'>Target audience</span>
                        <Select
                            isMultiple = { false }
                            items = { target_audiences }
                            value = { target_audience ? target_audience : ''}
                            title = { targetAudiences }
                            parameter = 'target_audience'
                            onChange = { onFormFieldChange }/>
                    </div>
                    <div className = 'gac-form-field gac-field-1-2'>
                        <span className = 'gac-form-label'>Content</span>
                        <Select
                            isMultiple = { false }
                            items = { content_types }
                            value = { content ? content : '' }
                            title = { contentData }
                            parameter = 'content'
                            onChange = { onFormFieldChange }/>
                    </div>
                </div>
            </> ;
        }

        return <>
            <div className="gac-brief-field">
                <div className="gac-brief-label">Target audience:</div>
                <div className="gac-brief-data">{targetAudiences}</div>
            </div>
            <div className="gac-brief-field">
                <div className="gac-brief-label">Content:</div>
                <div className="gac-brief-data">{ contentData }</div>
            </div>
        </> ;
    };

    const getTargetAudienceDropdownField = () => {
        const targetAudiences = getTitle(target_audiences, target_audience);

        if ( isEditing ) {
            return <>
                <div className = 'gac-form-row gac-row-multiple'>
                    <div className='gac-form-field gac-field-1-2'>
                        <span className='gac-form-label'>Target audience</span>
                        <Select
                            isMultiple = { false }
                            items = { target_audiences }
                            value = { target_audience ? target_audience : ''}
                            title = { targetAudiences }
                            parameter = 'target_audience'
                            onChange = { onFormFieldChange }/>
                    </div>
                </div>
            </> ;
        }

        return <>
            <div className="gac-brief-field">
                <div className="gac-brief-label">Target audience:</div>
                <div className="gac-brief-data">{targetAudiences}</div>
            </div>
        </> ;
    };

    const getTextareaField = (prop, value, label) => {
        let title = label;
        if(job_type === 'seobrief') {
            title = seobrief_type === 'page' ? 'Describe the service or product' : 'Describe the blog post topic';
        }

        if (isEditing) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>{title}</span>
                    <div id = {prop} className = { `gac-form-textarea ${invalidFields.includes(prop) ? 'gac-invalid' : ''}` }>
                        <textarea id = {`${prop}-textarea`} value = { value } readOnly />
                    </div>
                </div>
            </div>;
        }

        return <div className="gac-brief-field">
            { !isMobile && <span className = 'gac-brief-label'>{title}:</span> }
            <div className="gac-brief-data gac-with-files">
                <div className="gac-brief-content" dangerouslySetInnerHTML = {{ __html: setLinksTarget(value) }}/>
            </div>
        </div> ;
    };

    const getTextInputField = (prop, value, label) => {
        if ( isEditing ) {
            return <div className = 'gac-form-row'>
                <div className = 'gac-form-field'>
                    <span className = 'gac-form-label'>{label}</span>
                    <div className = 'gac-form-input'>
                        <input
                            className = { invalidFields.includes(prop) ? 'gac-invalid' : '' }
                            id = {prop}
                            data-hj-whitelist
                            value = { value }
                            type = 'text'
                            onBlur = { onInputBlur }
                            onChange = { onInputFieldChange }/>
                    </div>
                </div>
            </div> ;
        }

        return <div className="gac-brief-field">
            <div className="gac-brief-label">{ label }:</div>
            <div className="gac-brief-data">{ value }</div>
        </div> ;
    };

    /* SEO audit groups */
    const getSeoAuditTechnicalFields = () => {
        return <>
            { getWebsiteField() }
            { getSeoAuditedField() }
            { getCmsField() }
            { getFocusAreasField() }
            { getMonitoringToolsField() }
            { getCompetitorsField() }
            { getTargetAudienceTextarea() }
            { getTargetCountryField() }
            { getGoalsField() }
            { getGoogleAccessField() }
        </>;
    };
    const getSeoAuditCompetitorFields = () => {
        return <>
            { getWebsiteField() }
            { getCompetitorsField() }
            { getSeoStrategiesField() }
            { getLinkAnalyzedField() }
            { getTargetAudienceTextarea() }
            { getKeywordsField() }
            { getKeywordsGoogleField() }
            { getGoalsField() }
            { getGoogleAccessField() }
        </>;
    };
    const getSeoAuditFullFields = () => {
        return <>
            { getWebsiteField() }
            { getCompetitorsField() }
            { getTargetAudienceTextarea() }
            { getTargetCountryField() }
            { getSeoAuditNotesField() }
            { getCmsField() }
            { getFocusAreasField() }
            { getKeywordsGoogleField() }
            { getLinkToResourcesField() }
            { getMarketingChannelsField() }
            { getMonitoringToolsField() }
            { getGoalsField() }
            { getGoogleAccessField() }
        </>;
    };

    /* Html */
    const getEditBriefBtn = () => {
        if ( !includes(status, 'pitching/matching') ) return null;
        if ( job_type === 'design' && revision ) return null;

        return <div className = { `gac-project-btns gac-brief-page-btns ${isEditing ? 'gac-brief-btns' : ''}` }>
            <div className="gac-btn-v3" onClick = { isEditing ? onSubmit : onEditBrief }>
                <i className="gac-svg"><Pencil/></i><span>{ isEditing ? 'Save changes' : 'Edit brief' }</span>
            </div>
        </div> ;
    };
    const getDesignFields = () => {
        if ( job_type !== 'design' ) return null;
        return <>
            { getTopicField() }
            { getFormatOptionsField() }
            { getDimensionsField() }
            { getWorkflowField() }
            { getSitemapField() }
            { getBriefField() }
            { getFormatDescriptionField() }
            { getFormatTargetAudienceField() }
            { getFormatReasonField() }
            { getIncludedTextField() }
            { getDesignReferencesField() }
            { getImagesField() }
            { (image_option === 'browse-images' || image_option === 'find-images-for-me') && getImageNotesField() }
            { getBrandProfilesField() }
            { getUsedServicesField() }
            { getFileTypesField() }
            { getWritersField() }
            { getReviewFrequencyField() }
        </>;
    };
    const getKeywordsFields = () => {
        if ( job_type !== 'keywords' ) return null;
        return <>
            { getTopicField() }
            { getServicesField() }
            { getTargetAudienceField() }
            { getCompetitorsField() }
            { getTargetLocationField() }
            { getAdditionalDetailsField() }
        </>;
    };
    const getProofFields = () => {
        if ( job_type !== 'proofreading' ) return null;
        return <>
            { getIndustryField() }
            { getTopicField() }
            { getContentField() }
            { getBriefField() }
            { getWritersField() }
            { getLocalizationField() }
            { getCopyStyleVoiceField() }
        </>;
    };
    const getIdeasFields = () => {
        if ( job_type !== 'ideas' ) return null;
        return <>
            { getIndustryField() }
            { getTopicField() }
            { getProductServiceField() }
            { getTargetAudienceField() }
            { getContentGoalField() }
            { getBriefField() }
            { getLocalizationField() }
            { getWritersField() }
        </>;
    };
    const getWritingFields = () => {
        if ( job_type !== 'writing' ) return null;
        return <>
            { getIndustryField() }
            { getTopicField() }
            { getBriefField() }
            { getWritersField() }
            { getLocalizationField() }
            { getCopyStyleVoiceField() }
            { getAdditionalFields() }
        </>;
    };
    const getAihFields = () => {
        if ( job_type !== 'aih' ) return null;
        return <>
            { getIndustryField() }
            { getTopicField() }
            { getBriefField() }
            { getWritersField() }
            { getLocalizationField() }
            { getCopyStyleVoiceField() }
            { getAdditionalFields() }
        </>;
    };
    const getMotionFields = () => {
        if ( job_type !== 'motion' ) return null;
        return <>
            { getTopicField() }
            { getFormatOptionsField() }
            { getDimensionsField() }
            { getMotionAnimationLengthField() }
            { getAttachFileField() }
            { getBrandDescriptionField() }
            { getIdeasField() }
            { getExamplesField() }
            { getBrandProfilesField() }
            { getFileTypesField() }
            { getWritersField() }
            { getReviewFrequencyField() }
        </>;
    };
    const getWebauditFields = () => {
        if ( job_type !== 'webaudit' ) return null;
        return <>
            { getWebsiteField() }
            { getCmsField() }
            { getGoogleServicesField() }
            { getLinkToResourcesField() }
            { getProductsField() }
            { getCompetitorsField() }
            { getTargetAudienceTextarea() }
            { getGoalsField() }
            { getNotesField() }
        </>;
    };
    const getSeoAuditFields = () => {
        if ( job_type !== 'seoaudit' || isNil(seo_type) || isEmpty(seo_type) ) return null;

        const config = {
            'technical': getSeoAuditTechnicalFields(),
            'competitor': getSeoAuditCompetitorFields(),
            'full': getSeoAuditFullFields(),
        };
        return config[seo_type];
    };

    const getSeoBriefFields = () => {
        if ( job_type !== 'seobrief' ) return null;
        return <>
            { getTopicField() }
            { getSeoBriefTypeField() }
            { getTextareaField('brief', brief, 'Describe the service or product') }
            { getTextInputField('unique_selling', unique_selling, 'Unique selling proposition of the primary service or product') }
            { getTextInputField('competitors', competitors, 'Main competitor websites') }
            { getTextInputField('target_location', target_location, 'Target suburb, city, state or country') }
            { getTextInputField('target_keywords', target_keywords, 'Target keyword examples') }
            { getTextInputField('call_to_action', call_to_action, 'Desired call to action') }
            { getCopyStyleVoiceField() }
            { getTargetAudienceContentFields() }
            { getTextInputField('avoid', avoid, 'What to avoid') }
            { getTextInputField('content_reference', content_reference, 'Content reference (what you like)') }
            { getAdditionalDetailsField() }
        </>;
    };

    const getContentStrategyFields = () => {
        if ( job_type !== 'contentstrategy' ) return null;
        return <>
            { getTopicField() }
            { getTextareaField('brief', brief, 'Primary services or products') }
            { getTextInputField('unique_selling', unique_selling, 'Unique selling proposition of the primary service or product') }
            { getTargetAudienceDropdownField() }
            { getTextInputField('competitors', competitors, 'Main competitor websites') }
            { getTextInputField('target_location', target_location, 'Target suburb, city, state or country') }
            { getTextInputField('target_keywords', target_keywords, 'Target keyword examples') }
            { getTextInputField('content_themes', content_themes, 'Content themes: preferred and excluded topics') }
            { getAdditionalDetailsField() }
        </>;
    };

    const getLinkBuildingFields = () => {
        if ( job_type !== 'linkbuilding' ) return null;
        return <>
            { getTopicField() }
        </>;
    };
    
    const isEditBrief = 'pitching|matching'.includes(status);

    const classNames = cx( 'gac-project-content gac-brief-content', {
        'gac-editing': isEditing,
        'gac-keywords': job_type === 'keywords',
    });
    const contentObj = {
        'design': getDesignFields(),
        'keywords': getKeywordsFields(),
        'proofreading': getProofFields(),
        'ideas': getIdeasFields(),
        'writing': getWritingFields(),
        'aih': getAihFields(),
        'motion': getMotionFields(),
        'webaudit': getWebauditFields(),
        'seoaudit': getSeoAuditFields(),
        'seobrief': getSeoBriefFields(),
        'contentstrategy': getContentStrategyFields(),
        'linkbuilding': getLinkBuildingFields(),
    };

    return <div className = { classNames }>
        { !isMobile && !isEditing && <div className="gac-project-title">{ topic }</div> }
        <div className = { `gac-project-brief ${isEditBrief ? '' : 'gac-no-editing'}` } onDragOver = { isEditing ? onFormDragStart : null } onDrop = { isEditing ? onFormDrop : null }>
            <div className = 'gac-brief-wrap'>
                { contentObj[job_type] }
            </div>
        </div>
        { getEditBriefBtn() }
    </div> ;
};
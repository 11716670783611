export const fields = {
    order_form: ['format','industry','other_industry','level','topic','product_list','brief','order_on','written_by','localization'],
    keywords: ['type','project_id','job_type','level','topic','brief','order_on','written_by','planned_publish','project_tags','target_audience',
        'files','currency','competitors','target_location','additional_details','turnaround_custom','turnaround_type'],
    writing: ['type','project_id','job_type','format','industry','other_industry','word_count', 'idea_count', 'idea_project_id',
        'idea_number', 'level', 'topic','brief','smart_match','order_on','written_by','planned_publish','localization',
        'turnaround_custom','turnaround_type','project_tags','product_list','invite_writers','invite_writer_active_for','copy_style',
        'copy_voice','target_audience','turnaround','content','mention','avoid','similar_content','quantity','files','currency', 'is_subscription', 'talent','pay_type'],
    ideas: ['type','project_id','job_type','format','industry','other_industry','word_count', 'idea_count', 'level', 'topic','brief',
        'smart_match','order_on','written_by','planned_publish','localization','turnaround_custom','turnaround_type','product_list',
        'invite_writers','invite_writer_active_for','target_audience','turnaround','product_service','content_goal','quantity','files',
        'currency', 'is_subscription','talent','pay_type'],
    design: ['project_id','smart_match','job_type','level','invite_writers','invite_writer_active_for','topic', 'file_types', 'type',
        'format','dimensions','brief','files','included_text','design_references','written_by','order_on','planned_publish','image_option', 'workflow', 'sitemap', 'used_services',
        'image_notes','image_option_files','priority','brand_profiles','format_options','pay_type','format_description','format_target_audience','format_reason', 'review_frequency'],
    motion: ['project_id','job_type','type','format','topic','logo_file','company_details','brand_description','ideas','idea_files','style','examples','brand_profiles',
        'length','logo_description','order_on','written_by','format_options','pay_type','dimensions','file_types', 'invite_writers','invite_writer_active_for', 'review_frequency'],
    webaudit: ['project_id','job_type','type','level','website','cms','google_analytics','google_search_console','link_to_resources',
        'products','competitors','target_audience','goals','notes','order_on','written_by','pay_type'],
    seoaudit: ['project_id','job_type','type','website','cms','seo_audited','focus_areas','monitoring_tools','competitors','target_audience',
        'target_country_code','goals','google_access','seoaudit_type','seo_strategies','link_analized','keywords','keywords_google','seo_audit_notes','resources','marketing_channels','order_on','written_by','pay_type'],
    seobrief: [
        'project_id', 'type', 'website', 'level', 'seobrief_type', 'brief', 'unique_selling', 'competitors', 'target_location', 'target_keywords', 'call_to_action',
        'target_audience', 'content', 'copy_style', 'copy_voice', 'avoid', 'content_reference', 'additional_details', 'order_on', 'written_by', 'turnaround_custom', 'turnaround_type', 'attachments', 'files'
    ],
    contentstrategy: [
        'project_id', 'type', 'website', 'level', 'brief', 'unique_selling', 'competitors', 'target_location', 'target_keywords', 'content_themes',
        'target_audience', 'additional_details', 'order_on', 'written_by', 'turnaround_custom', 'turnaround_type', 'attachments', 'files'
    ],
};
export const unsaved = {
    writing: ['topic','brief','files'],
    proofreading: ['format','industry','topic','content','brief','files'],
    keywords: ['topic','brief','target_audience','competitors','additional_details','files'],
    ideas: ['format','industry','topic','product_service','target_audience','content_goal','brief'],
    design: [],
    blogging: [],
};
export const initial = {
    writing: {
        format:   '',
        industry: '',
        topic:    [''],
        brief:    '',
        files:    [],
    },
    proofreading: {
        format:   '',
        industry: '',
        topic:    [''],
        content:  '',
        brief:    '',
        files:    [],
    },
    keywords: {
        topic:               [''],
        brief:               '',
        target_audience:     '',
        competitors:         '',
        additional_details:  '',
        files:               [],
    },
    ideas: {
        format:          '',
        industry:        '',
        topic:           [''],
        product_service: '',
        target_audience: '',
        content_goal:    '',
        brief:           '',
    },
};
// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import {
    api,
    getKeys,
    getInitPath,
    getInitSearch,
    getCompanyBranding,
    getCompanyBilling,
    authStr,
    favicon,
    epiicFavicon,
    isAppDev,
    isAppQa,
    isAppYv,
    isAppVi,
    isAppRa,
    domainGAC,
    epiic,
    getPaymentsType,
} from '../../../../helpers/api';
import {
    cleanObject,
    changeFavicon,
    isWhiteLabel,
    isEpiic,
    isProd,
    isLocal, getApp, getPort, getProtocol
} from '../../../../helpers/helpers';
import { authActions } from '../../../auth/actions';
import { uiActions } from '../../../ui/actions';
import { userActions } from '../../actions';
import { isNil, includes, isEmpty } from 'ramda';

export function* callFetchCompanyWorker ({ payload: { action }}) {
    try {
        yield put(uiActions.enableFetching());
        const isBilling = action.name === 'get_company_billing';
        const { privateKey, publicKey, accountId, sessid } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/company${isBilling ? '/billing' : ''}`,{
            method: 'GET',
            headers: {
                Authorization: `${authStr} ${privateKey}`
            }
        });
        let { response: company, error: { message }} = yield call([response, response.json]);
        const { white_label, white_label_confirmed, domain_name, host } = company;

        if (response.status !== 200) {
            throw new Error(message);
        }
        company = cleanObject(company);
        const path = `privateKey=${privateKey}&publicKey=${publicKey}&accountId=${accountId}&sessid=${sessid}`;
        if ( isBilling ) {
            const billing = yield select(getCompanyBilling);
            yield put(userActions.setUserCompanyState('billing', { ...billing, ...company }));
        } else {
            const initPath = yield select(getInitPath);
            const initSearch = yield select(getInitSearch);
            const paymentsType = yield select(getPaymentsType);
            const port = !isNil(getPort()) ? `:${getPort()}` : "";
            const protocol = getProtocol();
            let subPath = '';
            let redirectUrl = null;

            // Проверяем host и перенаправляем если надо

            // Если сконфигурирован White label и текущий Url не совпадает с White label доменом
            if (
                !isNil(white_label_confirmed)
                && white_label_confirmed
                && white_label
                && domain_name
                && window.location.hostname !== domain_name
                )
            {

                if ( includes('/company/new', initPath) && includes(paymentsType, 'writing/blogging/design/bundle/webbuilding') ) {
                    subPath = initSearch;
                }
                const isSearch = includes('?', initPath) || includes('?', subPath) ;

                yield put(authActions.clearCookies());
                redirectUrl = `${protocol}//${domain_name}${port}${initPath}${subPath}${isSearch ? '&' : '?'}${path}`;
            }

            if ( isNil(redirectUrl) && !isNil(host) && !isEmpty(host) && !isLocal ) {
                if ( includes('/company/new', initPath) ) {
                    subPath = `${initPath}${initSearch}`;
                }
                else if ( includes('/prepay', initPath) ) {
                    subPath = `${initPath}${initSearch}`;
                }
                const isSearch = includes('?', subPath) ;
                const app = getApp();

                if (isNil(redirectUrl) && host === 'gac' && isEpiic(host) ) {
                    yield put(authActions.clearCookies());
                    redirectUrl = `${protocol}//${app}.${domainGAC}.com${port}${subPath}${isSearch ? '&' : '?'}${path}`;
                } else if (isNil(redirectUrl) && host === 'epiic' && includes(domainGAC, window.location.href) ) {
                    yield put(authActions.clearCookies());
                    redirectUrl = `${protocol}//${app}.${epiic}.com${port}${subPath}${isSearch ? '&' : '?'}${path}`;
                }
            }

            if(!isNil(redirectUrl)) {
                console.debug(`Redirect to: ${redirectUrl}`);
                window.location.href = redirectUrl;
                return;
            }

            // if ( isWhiteLabel() && !white_label && !isLocal ) {
            //     yield put(authActions.clearCookies());
            //     window.location.href = `https://app.${domainGAC}.com`;
            //     return;
            // }
            const branding = yield select(getCompanyBranding);
            yield put(userActions.setUserCompanyState('branding', { ...branding, ...company }));
            yield put(uiActions.setUiState('isCompanyLoaded', true));
            yield put(uiActions.setUiState('isWhiteLabel', white_label));
            changeFavicon(
                isProd || isLocal || isAppDev || isAppQa || isAppYv || isAppVi || isAppRa
                    ? isEpiic(host)
                        ? epiicFavicon
                        : favicon
                    : isWhiteLabel()
                        && !isNil(company.favicon)
                        && !isNil(company.favicon.url)
                            ? company.favicon.url
                            : null );
            // if ( !isNil(window.$crisp) ) {
            //     window.$crisp.push(["set", "session:data", [
            //         [
            //             ["link", `https://secure.getacopywriter.com/a/company/${}`],
            //             ["Company", 'True'],
            //         ]
            //     ]]);
            // }
            // TODO window.$crisp.push company.company_id
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}

import { array, object, string, boolean, number, mixed, ref } from 'yup';
import { includes, isNil } from "ramda";
import { getDimensions } from "./helpers";

const isString = (label) => string().required().label(label);
const format = isString('format');
const industry = isString('industry');
const other_industry = string().when('industry', {
    is: val => val === 'other',
    then: () => isString('other_industry'),
});
const level = isString('level');
const brief = string().when('isBriefLimit', {
    is: limit => limit,
    then: () => string().max(30000).label('brief'),
    otherwise: () => string().when('brief_links_shared', {
        is: links => links === 'no',
        then: () => string().min(40000).label('brief'),
        otherwise: () => isString('brief'),
    }),
});
const topic = array().of(string().notOneOf([' ', '']).max(150).label('topic'));
const topic_array = array().when('format', {
    is: val => val !== 'product',
    then: () => array().when('quantity', {
        is: val => val === 1,
        then: () => topic,
        otherwise: () => array().of(string().notOneOf([' ', '']).max(150).label('topic_array')).min(ref('quantity')).label('topic_array'),
    }),
});
const product_list = array().when('format', {
    is: val => val === 'product',
    then: () => array().when('quantity', {
        is: val => val === 1,
        then: () => array().of(string().notOneOf([' ', '']).max(150).label('product_list')),
        otherwise: () => array().of(string().notOneOf([' ', '']).max(150).label('product_list_array')).min(ref('quantity')).label('product_list_array'),
    }),
});
const progress = array().length(0).label('attachment');
// const currency = string().when('isAuth', {
//     is: val => !val,
//     then: () => isString('currency'),
// });

// Design
const format_options = array().when('format', {
    is: val => val === '',
    then: () => array(),
    otherwise: () => array().of(
        object({
            custom: boolean(),
            amount: number().when('custom', {
                is: true,
                then: () => number().moreThan(0).label('custom_quote'),
                otherwise: () => string(),
            }),
        }),
    ).min(1).label('format_options'),
});
const format_description = string().when('format', {
    is: val => val === '28',
    then: () => string().notOneOf([' ', '']).required().label('format_description'),
    otherwise: () => string(),
});
const format_target_audience = string().when('format', {
    is: val => val === '28',
    then: () => string().notOneOf([' ', '']).required().label('format_target_audience'),
    otherwise: () => string(),
});
const format_reason = string().when('format', {
    is: val => val === '28',
    then: () => string().notOneOf([' ', '']).required().label('format_reason'),
    otherwise: () => string(),
});
const design_references = string().notOneOf([' ', '']).required().label('design_references');
const image_notes = string().when('image_option', {
    is: val => val === 'find-images-for-me',
    then: () => isString('image_notes'),
});
const file_types = array().min(1).label('file_types');
const dimensions = array().when(['job_type', 'formats', 'format'], {
    is: (job_type, formats, format) => !isNil(getDimensions(formats[job_type], format)),
    then: () => array().min(1).label('dimensions'),
});

// Motion
const logo_file = array().min(1).label('attachment');
const ideas = string().notOneOf([' ', '']).required().label('ideas');
const examples = string().notOneOf([' ', '']).required().label('examples');
const length = string().notOneOf([' ', '']).required().label('length');
const website = isString('website');
const cms = isString('cms');
const competitors = string().notOneOf([' ', '']).required().label('competitors');
const target_audience = string().notOneOf([' ', '']).required().label('target_audience');
const focus_areas = string().notOneOf([' ', '']).required().label('focus_areas');
const keywords_google = string().notOneOf([' ', '']).required().label('keywords_google');
const monitoring_tools = string().notOneOf([' ', '']).required().label('monitoring_tools');
const goals = string().notOneOf([' ', '']).required().label('goals');
const google_access = string().oneOf(['done', 'help']).label('google_access');
const review_frequency = string().oneOf(['every_business_day', 'upon_project_completion']).label('review_frequency');

const writingOneOff = object({
    isAuth: boolean(),
    quantity: number(),
    format,
    industry,
    other_industry,
    level,
    topic: topic_array,
    product_list,
    brief,
    progress,
    word_count_type: string().when(['word_count', 'quantity'], {
        is: (count, qty) => qty === 1 && !count,
        then: () => isString('word_count_type'),
    }),
    word_count: mixed().when('quantity', {
        is: val => val !== 1,
        then: () => array().of(number().moreThan(0).label('word_count_array')).min(ref('quantity')).label('word_count_array'),
    }),
    order_on: mixed().when(['format', 'quantity', 'isAllTopicsOneDay'], {
        is: (format, qty, isAllTopicsOneDay) => format !== 'product' && qty !== 1 && !isAllTopicsOneDay,
        then: () => array().of(string().notOneOf([' ', '']).label('order_on')).min(ref('quantity')).label('order_on'),
    }),
});

export const orderSchema = {
    design: {
        'one-off': object({
            isBriefLimit: boolean(),
            format,
            format_options,
            dimensions,
            topic,
            brief,
            progress,
            format_description,
            format_target_audience,
            format_reason,
            design_references,
            image_notes,
            file_types,
            review_frequency,
        }),
        'subscription': object({
            isBriefLimit: boolean(),
            format,
            dimensions,
            topic,
            brief,
            progress,
            format_description,
            format_target_audience,
            format_reason,
            design_references,
            image_notes,
            file_types,
            review_frequency,
        }),
    },
    motion: {
        'one-off': object({
            format,
            format_options,
            dimensions,
            topic,
            logo_file,
            ideas,
            examples,
            length,
            progress,
            file_types,
            review_frequency,
        }),
        'subscription': object({
            format,
            dimensions,
            topic,
            logo_file,
            ideas,
            examples,
            length,
            progress,
            file_types,
            review_frequency,
        }),
    },
    writing: {
        'one-off': writingOneOff,
        'subscription': object({
            format,
            industry,
            other_industry,
            topic: topic_array,
            product_list,
            brief,
            progress,
            word_count: mixed().when(['words_balance', 'action'], {
                is: (balance, action) => (balance < 1 || !balance) && includes(action, 'save_add_to_order/update_add_to_order'),
                then: () => string().label('word_count'),
                otherwise: () => number().min(100).required().label('word_count'),
            })
        }),
    },
    blogging: {
        'one-off': writingOneOff,
        existing: object({
            brief,
            progress,
            word_count: number().min(100).required().label('word_count'),
        }),
        request: object({
            industry,
            other_industry,
            topic,
            product_service: isString('product_service'),
            target_audience: isString('target_audience'),
            content_goal: isString('content_goal'),
            brief,
            progress,
            word_count: number().min(1).required().label('word_count'),
        }),
        own: object({
            industry,
            other_industry,
            topic,
            brief,
            progress,
            word_count: mixed().when(['words_balance', 'action'], {
                is: (balance, action) => (balance < 1 || !balance) && includes(action, 'save_add_to_order/update_add_to_order'),
                then: () => string().label('word_count'),
            })
        }),
    },
    aih: writingOneOff,
    ideas: object({
        format,
        industry,
        other_industry,
        level,
        topic: array().when('format', {
            is: val => val !== 'product',
            then: () => array().of(string().notOneOf([' ', '']).max(150).label('topic')),
        }),
        product_list,
        brief,
        progress,
        idea_count_type: string().when('idea_count', {
            is: count => !count,
            then: () => isString('idea_count_type'),
        }),
        product_service: isString('product_service'),
        content_goal: isString('content_goal'),
        target_audience: isString('target_audience'),
        word_count: number().min(1).required().label('word_count'),
    }),
    proofreading: object({
        format,
        industry,
        other_industry,
        topic: array().when('format', {
            is: val => val !== 'product',
            then: () => array().of(string().notOneOf([' ', '']).max(150).label('topic')),
        }),
        product_list,
        content: number().min(10).label('content'),
        brief,
        progress,
        word_count_type: string().when(['word_count'], {
            is: count => !count,
            then: () => isString('word_count_type'),
        }),
        level,
    }),
    keywords: object({
        isAuth: boolean(),
        topic,
        brief,
        target_audience: string().notOneOf([' ', '']).required().label('target_audience'),
        competitors: isString('competitors'),
        target_location: isString('target_location'),
    }),
    webaudit: object({
        website: isString('website'),
        cms: isString('cms'),
        google_analytics: string().oneOf(['yes', 'no', 'unknown']).label('google_analytics'),
        google_search_console: string().oneOf(['yes', 'no', 'unknown']).label('google_search_console'),
        link_to_resources: isString('link_to_resources'),
        products: string().notOneOf([' ', '']).required().label('products'),
        competitors: string().notOneOf([' ', '']).required().label('competitors'),
        target_audience: string().notOneOf([' ', '']).required().label('target_audience'),
        goals,
    }),
    seoaudit: {
        technical: object({
            website,
            cms,
            focus_areas,
            monitoring_tools,
            competitors,
            target_audience,
            goals,
            google_access,
        }),
        competitor: object({
            website,
            competitors,
            link_analized: string().oneOf(['yes', 'no', 'unknown']).label('link_analized'),
            target_audience,
            keywords: string().notOneOf([' ', '']).required().label('keywords'),
            keywords_google,
            goals,
            google_access,
        }),
        full: object({
            website,
            competitors,
            target_audience,
            cms,
            focus_areas,
            keywords_google,
            marketing_channels: string().notOneOf([' ', '']).required().label('marketing_channels'),
            monitoring_tools,
            goals,
            google_access,
        })
    },
    seobrief: object({
        website,
        seobrief_type: string().oneOf(['page', 'post']).label('seobrief_type'),
        brief,
        competitors: string().notOneOf([' ', '']).required().label('competitors'),
        target_location: isString('target_location'),
        target_keywords: isString('target_keywords'),
        call_to_action: isString('call_to_action'),
    }),
    contentstrategy: object({
        website,
        brief,
        target_audience,
        competitors: string().notOneOf([' ', '']).required().label('competitors'),
        target_location: isString('target_location'),
        target_keywords: isString('target_keywords')
    })
};

export const orderDraftSchema = object({
    topic
})
// Core
import { useLayoutEffect } from "react";

// Components
import { DesignInfo } from "../JobTypesInfo/DesignInfo";

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import { useUser } from "../../../hooks/useUser";

// Instruments
import { initDefaultRedactor, getActiveDesignSub, isActiveWebBuildingSub } from "../../../helpers/helpers";
import { isNil } from "ramda";
import { func } from "prop-types";

export const MotionSubscription = ({ fields }) => {
    /* Hooks */
    const {
        getMotionFormatField,
        getDimensionsField,
        getTopicField,
        getAttachFileField,
        getTextareaField,
        getMotionAnimationLengthField,
        getFileTypesField,
        getBrandProfilesField,
        getMotionIdeasDropzoneHtml,
        getWritersField,
        getReviewFrequencyField,

        onRedactorSynced,
        onRedactorBlur
    } = fields;
    const { keys: { accountId } } = useAuth();
    const { company: { subscriptions } } = useUser();

    useLayoutEffect(() => {
        initDefaultRedactor('#brand_description-textarea', 'brand_description', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#ideas-textarea', 'ideas', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#examples-textarea', 'examples', '116px', null, onRedactorSynced, onRedactorBlur, true);

        return () => {
            window.$R('#brand_description-textarea', 'destroy');
            window.$R('#ideas-textarea', 'destroy');
            window.$R('#examples-textarea', 'destroy');
        };
    }, []);

    const activeDesignSubscription = getActiveDesignSub(accountId, subscriptions);
    const planName = activeDesignSubscription?.plan_name;
    const isReturnSubscriptionForm = getIsReturnSubscriptionForm();
    const isIncludeInfoAboutUpgrade = getIsIncludeInfoAboutUpgrade();

    function getIsReturnSubscriptionForm() {
        // Если есть активная webdev подписка, показываем форму
        if(isActiveWebBuildingSub(accountId, subscriptions)) {
            return true;
        }

        // Нет активной дизайн подписки (webdev проверили выше) - не показываем форму
        if (isNil(activeDesignSubscription)) {
            return false;
        }

        // Если есть активная design подписка, смотрим на ее план.
        if (!isNil(activeDesignSubscription)) {
            switch (planName) {
                case 'Pro':
                    return false;
                    break;
                case 'Double':
                    return false;
                    break;
            }
        }

        return true;
    }

    function getIsIncludeInfoAboutUpgrade() {
        if (!isNil(activeDesignSubscription)) {
            switch (planName) {
                case 'Pro':
                    return true;
                    break;
                case 'Double':
                    return true;
                    break;
            }
        }

        return false;
    }

    if (isReturnSubscriptionForm) {
        return <>
            {getMotionFormatField()}
            {getDimensionsField()}
            {getMotionAnimationLengthField()}
            {getTopicField()}
            {getAttachFileField()}
            {getTextareaField('brand_description', 'Text to be included in the motion graphic, word for word (leave blank if none)')}
            {getTextareaField('ideas', 'Your ideas/storyboard/script for the animation', true, getMotionIdeasDropzoneHtml)}
            {getTextareaField('examples', 'Links to examples of animations you like')}
            {getBrandProfilesField()}
            {getFileTypesField()}
            {getWritersField()}
            {getReviewFrequencyField()}
        </>;
    }

    let description = <><h3>Motion graphics</h3><p>for a flat monthly fee</p></>;

    if (isIncludeInfoAboutUpgrade) {
        description = <><h3>Your current plan does not include Motion graphics</h3><p>Upgrade to get access</p></>;
    }

    return <DesignInfo description={description} />;
};